<template>
  <d-container v-if="tableId" fluid :class="`main-content-container ${zeroPad ? 'px-0 py-0' : 'px-4 pb-4'} vld-parent`">
    <loading-overlay :isLoading="isLoading || !user" />
    <progress-modal :progress="progress" @cancel="progress = null" />
    <d-row no-gutters class="page-header py-4">
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <span v-if="section" class="text-uppercase page-subtitle">{{ section }}</span>
        <slot name="title">
          <h3 v-if="title" class="page-title">{{ title }}</h3>
        </slot>
      </d-col>
      <slot name="header">
        <d-col col sm="8" class="my-auto">
        </d-col>
      </slot>
    </d-row>
    <d-row no-gutters class="pb-4">
      <d-col col lg="12" md="12" sm="12">
        <slot name="subheader">
        </slot>
      </d-col>
    </d-row>
    <template v-if="user && !hideTable">
      <v-server-table v-if="api || requestFunction" :name="tableId"
        :url="api" :columns="columns" :options="options_"
        ref="table"
        :data-ref="`table.${api ? api.replace(/\/\?.*$/g, '').replace(/\//g, '.') : title.replace(/\s/g, '').toLowerCase()}`"
        @row-click="toggleChildRow($event.row)"
        @loading="startLoading"
        @loaded="stopLoading"
        @error="stopLoading"
        class="dataTables_wrapper device-manager device-manager-list">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
        <div slot="afterFilterWrapper" class="my-auto after-filter">
          <d-link v-if="addName" @click.native.prevent="$emit('addItem')" class="ml-3">
            <i class="fa fa-plus mr-1" />Add {{ addName }}
          </d-link>
          <d-link v-if="csv || csvFunction" class="ml-3" @click.native.prevent="csvDownload">
            <i class="fa fa-download mr-1" />
            <span>Download CSV</span>
          </d-link>
          <d-link v-if="!noRefresh" @click.native.prevent="refresh" class="ml-3">
            <i class="fa fa-redo-alt mr-1" />Refresh
          </d-link>
          <download-csv ref="download" v-show="false" :fetch="serverFetch" :fields="csvFields" :defaultValue="csvDefaultValue" type="csv" />
          <slot name="afterTableHeader"></slot>
        </div>
      </v-server-table>
      <v-client-table v-else-if="user" :name="tableId || `${section}-${title}`"
        :data="items" :columns="columns" :options="options_"
        ref="table"
        :data-ref="title ? `table.${title.replace(/\s/g, '').toLowerCase()}` : tableId"
        @row-click="toggleChildRow($event.row)"
        class="dataTables_wrapper device-manager device-manager-list">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
        <div slot="afterFilterWrapper" class="my-auto after-filter">
          <d-link v-if="addName" @click.native.prevent="$emit('addItem')" class="ml-3">
            <i class="fa fa-plus mr-1" />Add {{ addName }}
          </d-link>
          <d-link v-if="csv || csvFunction" class="ml-3" @click.native.prevent="csvDownload">
            <i class="fa fa-download mr-1" />
            <span>Download CSV</span>
          </d-link>
          <d-link v-if="!noRefresh" @click.native.prevent="$emit('refresh')" class="ml-3">
            <i class="fa fa-redo-alt mr-1" />Refresh
          </d-link>
          <download-csv ref="download" v-show="false" :fetch="csvClientFetch" :fields="csvFields" :defaultValue="csvDefaultValue" type="csv" />
          <slot name="afterTableHeader"></slot>
        </div>
      </v-client-table>
    </template>
    <d-row no-gutters class="page-footer py-4">
      <slot name="footer">
      </slot>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters } from 'vuex'
import '@/assets/scss/vue-tables.scss'
import '@/assets/scss/device-manager-list.scss'
import merge from 'deepmerge'
import api from '@/lib/api'
import ProgressModal from '@/components/ProgressModal'
import DownloadCsv from '@/components/DownloadCsv'
import moment from 'moment-timezone'
import utils from '@/mixins/utils'

const timeZones = {
  Hawaii: 'Pacific/Honolulu',
  Alaska: 'America/Anchorage',
  Pacific: 'America/Los_Angeles',
  Mountain: 'America/Denver',
  Central: 'America/Chicago',
  Eastern: 'America/New_York'
}

export default {
  name: 'item-list',
  props: {
    tableId: String,
    section: String,
    title: String,
    items: Array,
    api: String,
    columns: Array,
    options: Object,
    addName: String,
    loading: Boolean,
    csv: Object,
    csvFetch: Function,
    csvDefaultValue: { type: undefined, default: '' },
    hasChild: Function,
    rowKey: String,
    zeroPad: Boolean,
    noRefresh: Boolean,
    requestFunction: Function,
    csvFunction: Function,
    hideTable: Boolean
  },
  mixins: [utils],
  data () {
    return {
      options_: {
        perPage: 10,
        perPageValues: [10, 25, 50, 100, 200],
        skin: 'device-manager device-manager-list table dataTable',
        sortIcon: {
          base: 'fas float-right mt-1 text-muted',
          up: 'fa-caret-up',
          down: 'fa-caret-down'
        },
        texts: {
          filterPlaceholder: '',
          limit: 'Show'
        },
        pagination: {
          edge: true,
          nav: 'scroll',
          chunk: 5
        },
        filterByColumn: true,
        columnsClasses: {
          actions: 'actions'
        },
        customSorting: {
          ago: (asc) => (a, b) => {
            a = ((a && a.ts) || {}).seconds || 0
            b = ((b && b.ts) || {}).seconds || 0
            return asc ? a - b : b - a
          },
          ...(this.options || {}).customSorting
        },
        serverMultiSorting: true,
        showChildRowToggler: false,
        uniqueKey: this.rowKey || 'id',
        rowClassCallback: (row) => {
          if (this.canToggleRow(row)) {
            return 'has-child-row'
          }
          return 'no-child-row'
        },
        debounce: 200,
        saveState: true,
        storage: 'session',
        requestFunction: this.requestFunction || (async (params) => {
          this.params = params
          const result = await api.get(this.api, { params: { ...params, ...this.options.extraParams } }).catch(error => console.error(error))
          if (result && result.data && result.data.count !== undefined) {
            this.count = result.data.count
            return result
          } else {
            this.stopLoading()
          }
        }),
        headings: {},
        ...this.options
      },
      csvFields: {},
      serverLoading: false,
      params: null,
      count: 0,
      timezone: null,
      progress: null
    }
  },
  components: {
    ProgressModal,
    DownloadCsv
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    isLoading () {
      return this.serverLoading || this.loading
    }
  },
  methods: {
    refresh () {
      this.$refs.table.refresh()
      this.$emit('refresh')
    },
    add (item, { throws, params = {} } = {}) {
      this.startLoading()
      return api.post(`${this.api}`, item, { params })
        .then(() => {
          this.refresh()
        })
        .catch((error) => {
          if (throws) {
            throw error
          } else {
            alert(`Sorry, could not add: ${error.reason || error.message}.`)
          }
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    update (id, changes, { throws, params = {}, path } = {}) {
      this.startLoading()
      return api.put(`${path || this.api}/${id}`, changes, { params })
        .then((updated) => {
          const data = this.$refs.table.data
          const index = data.findIndex(item => item.id === id)
          if (index < 0) {
            this.refresh()
          } else {
            data.splice(index, 1, merge(data[index], updated))
            this.refresh()
          }
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          if (throws) {
            throw error
          } else {
            alert(`Sorry, could not update: ${error.reason || error.message}.`)
          }
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    delete (id, { params = {}, path } = {}) {
      this.startLoading()
      return api.delete(path || `${this.api}/${id}`, { params })
        .then(() => {
          const data = this.$refs.table.data
          const index = data.findIndex(item => item.id === id)
          if (index < 0) {
            this.refresh()
          } else {
            data.splice(index, 1)
          }
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not delete: ${error.reason || error.message}.`)
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    startLoading () {
      this.serverLoading = true
    },
    stopLoading () {
      this.serverLoading = false
    },
    getCsvFields (params) {
      const headings = (this.options || {}).headings || {}
      let fields = Object.fromEntries(this.columns
        .filter(field => field !== 'actions')
        .map(field => [headings[field] || (field[0].toUpperCase() + field.slice(1)), field])
        .filter(Boolean)
      )
      const extraFields = {}
      const customFields = params ? params.fields || params || {} : {}
      for (const key in customFields) {
        if (key in fields) {
          fields[key] = customFields[key]
        } else {
          extraFields[key] = customFields[key]
        }
      }
      if (params && params.suffixExtraFields) {
        fields = {
          ...fields,
          ...extraFields
        }
      } else {
        fields = {
          ...extraFields,
          ...fields
        }
      }
      for (const key in fields) {
        if (!fields[key]) {
          delete fields[key]
        }
      }
      return fields
    },
    csvDownload () {
      if (typeof this.csv === 'function') {
        this.csvFields = (rows) => this.getCsvFields(this.csv(rows))
      } else {
        this.csvFields = this.getCsvFields(this.csv)
      }
      if (this.csvFunction) {
        return this.csvFunction()
      }
      if (this.csv.timeZones) {
        const now = new Date()
        const options = Object.entries(timeZones).map(([label, zone]) => {
          return {
            value: zone,
            text: `${label} (${moment.tz(now, zone).format('Z z')})`
          }
        })
        this.editModal({
          label: 'Download CSV',
          fields: [
            { id: 'timezone', name: 'Time Zone', value: moment.tz.guess(), options }
          ],
          onSubmit: async ({ timezone }) => {
            this.timezone = timezone
            this.$refs.download.$el.click()
          }
        })
      } else {
        this.$refs.download.$el.click()
      }
    },
    async serverFetch (type = 'CSV', extraParams) {
      if (this.count > 100000) {
        alert(`${type} download only support a maximum of 100000 rows. Please add more filters.`)
        return
      }
      let canceled = false
      try {
        let result = []
        // Show a progress bar
        this.updateProgress({
          step: `Downloading ${type} data…`,
          percent: 0
        })
        // Split total into 500 records apiece and fetch chunk by chunk
        const chunks = Math.ceil(this.count / 500)
        for (let i = 1; i <= chunks; ++i) {
          const params = {
            ...this.params,
            ...extraParams,
            ...(type === 'CSV' && { orderBy: 'id' }),
            page: i,
            limit: 500
          }
          const { data } = await api.get(this.api, { params }).catch(error => console.error(error))
          if (!this.progress) {
            canceled = true
            throw new Error('Download canceled')
          }
          result = result.concat(data.data)
          this.updateProgress({
            step: `Downloading ${type} data…`,
            percent: (i / chunks) * 100.0
          })
          if (i === chunks) {
            await this.delay(200)
          }
        }
        return result
      } catch (error) {
        console.log(error)
        this.updateProgress()
        alert(`${canceled ? 'Download canceled' : 'Failed to download ' + type + ' data: ' + error}`)
      } finally {
        this.updateProgress()
      }
    },
    async csvClientFetch () {
      this.startLoading()
      try {
        if (this.csvFetch) {
          return this.csvFetch()
        } else {
          return this.items
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.stopLoading()
      }
    },
    getLocalTimeString (time) {
      try {
        if (this.timezone) {
          return moment.tz(time, this.timezone).format('YYYY-MM-DD HH:mm:ss z')
        }
      } catch (error) {
        console.log(error)
      }
      return time
    },
    updateProgress (progress) {
      this.progress = progress && { ...progress }
    },
    canToggleRow (row) {
      if (typeof this.hasChild === 'function') {
        return this.hasChild(row)
      }
      return Boolean(this.hasChild)
    },
    toggleChildRow (row) {
      if (this.canToggleRow(row)) {
        this.$refs.table.toggleChildRow(row[this.rowKey || 'id'])
      }
      this.$emit('row-click', row)
    },
    setFilter (filter) {
      this.$refs.table.setFilter(filter)
    }
  }
}
</script>

<style scoped>
  .after-filter {
    padding: 13px 20px;
  }
  div.csv {
    display: inline;
  }
</style>

<style>
.table-responsive {
  overflow-x: scroll !important;
}
.VueTables__table th {
  min-width: 150px;
}
.VueTables__child-row.no-child-row {
  visibility: collapse
}
.VueTables__row.has-child-row:hover {
  background-color: #e9ecef;
}
.VueTables__child-row {
  background-color: #e9ecef5a;
}
.VueTables__child-row .child-table {
  padding: 20px;
}
.VueTables__child-row .child-table-name {
  margin-bottom: 20px;
  padding: 5px;
  background-color: #e9ecef;
}
</style>
