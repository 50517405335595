<template>
  <l-control v-if="visible" :position="position" class="leaflet-bar leaflet-control">
    <a href="#" @click="onClickRefresh" title="Refresh markers">
      <i class="fa fa-sync-alt" :class="isRefreshing ? 'fa-spin' : ''" />
    </a>
    <a href="#" @click="onToggle('labels')" title="Toggle labels" :class="state.labels && 'on'">
      <i class="fa fa-tags" />
    </a>
    <a href="#" @click="onToggle('diagnostics')" title="Toggle diagnostics" :class="state.diagnostics && 'on'">
      <i class="fa fa-chart-bar" />
    </a>
    <a href="#" @click="$emit('filter')" title="Filter markers" :class="(!state.markerTypeFilter.includes('all') || !state.markerLabelFilter.includes('all')) && 'on'">
      <i class="fa fa-filter" />
    </a>
  </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet'

export default {
  props: {
    position: {
      type: String,
      default: 'topleft'
    },
    visible: {
      type: Boolean,
      default: true
    },
    isRefreshing: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Boolean,
      default: false
    },
    diagnostics: {
      type: Boolean,
      default: false
    },
    markerTypeFilter: {
      type: Array,
      default: () => { return ['all'] }
    },
    markerLabelFilter: {
      type: Array,
      default: () => { return ['all'] }
    }
  },
  components: {
    LControl
  },
  data () {
    return {
      state: {
        labels: false,
        diagnostics: false,
        markerTypeFilter: ['all'],
        markerLabelFilter: ['all']
      }
    }
  },
  watch: {
    labels () {
      this.state.labels = this.labels
    },
    diagnostics () {
      this.state.labels = this.diagnostics
    },
    markerTypeFilter () {
      this.state.markerTypeFilter = this.markerTypeFilter
    },
    markerLabelFilter () {
      this.state.markerLabelFilter = this.markerLabelFilter
    }
  },
  methods: {
    onClickRefresh () {
      this.$emit('click:refresh')
    },
    onToggle (name) {
      if (!(name in this)) return
      this.state[name] = !this.state[name]
      this.$emit(`toggle:${name}`, this.state[name])
    }
  }
}
</script>

<style scoped>
a.on {
  background-color: #007bff;
}

a.on i.fa {
  color: white;
}
</style>
