<template>
  <span class="w-100 d-none d-md-flex d-lg-flex my-auto ml-4">
    {{ consoleName }}
  </span>
</template>

<script>
import { consoleConfig } from '@/lib/console'
import utils from '@/mixins/utils'

export default {
  mixins: [utils],
  data () {
    return {
      consoleConfig: {}
    }
  },
  computed: {
    consoleName () {
      return this.consoleConfig.name || ''
    }
  },
  async mounted () {
    await this.updateConsoleConfig()
    this.interval = setInterval(() => {
      this.updateConsoleConfig()
    }, 60000)
  },
  destroyed () {
    clearInterval(this.interval)
  },
  methods: {
    async updateConsoleConfig () {
      try {
        this.consoleConfig = await consoleConfig() || {}
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
