import Auth from './auth'
import config from '@/config'
import axios from 'axios'

export default class PasswordAuth extends Auth {
  method () {
    return 'password'
  }

  async login ({ email, password, after }) {
    const query = after ? `?after=${after}` : ''
    const url = `${config.api.server}${config.api.path}/auth/password/${this.slug()}${query}`

    const { data } = await axios.post(url, {
      username: email,
      password
    })
    return data
  }
}
