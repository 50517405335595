<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <d-radio v-for="item in radioList" :key="item.value" v-model="selected" :value="item.value" v-d-tooltip.hover.left.no-fade="item.tooltip">{{ item.text }}</d-radio>
  </div>
</template>

<script>

export default {
  name: 'radio-list',
  props: ['value', 'radioList', 'label'],
  data () {
    return {
      selected: this.value
    }
  },
  watch: {
    selected (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style>
.vue-simple-suggest.designed .input-wrapper input {
  padding: 8px;
}
.vue-simple-suggest.designed svg {
  fill: currentColor;
}
.vue-simple-suggest.designed .input-group-text:hover {
  color: #e53935;
}
.vue-simple-suggest.designed .footer-item {
  font-weight: bold;
  font-size: 0.7rem;
}

</style>
