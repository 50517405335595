<template>
  <d-row no-gutters class="h-100">
    <loading-overlay :isLoading="(!auth && !error) || loading" />
    <d-col lg="3" md="5" class="auth-form mx-auto my-auto">
      <d-card>
        <d-card-body>
          <!-- Logo -->
          <img class="auth-form__logo d-table mx-auto mb-4" style="max-width: 200px;"
            :src="`/img/${$brand.domain}/logo.png`" :alt="$brand.name">

          <!-- Form Fields -->
          <d-alert theme="danger" show v-if="!!error">{{ error.message }}</d-alert>
          <d-form @submit.prevent="login">
            <template v-if="method === 'key'">
              <div class="form-group">
                <label for="key">Key</label>
                <d-input type="password" id="key" v-model="credentials.key" placeholder="Enter Key" />
              </div>
              <d-button pill :disabled="loading || !credentials.key.length" type="submit"
                class="btn-accent d-table mx-auto mt-0">Sign in to {{ $brand.name }}</d-button>
            </template>
            <template v-else-if="method === 'password'">
              <div class="form-group">
                <label for="email">Email address</label>
                <d-input id="email" v-model="credentials.email" placeholder="Enter email" />
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <d-input type="password" id="password" v-model="credentials.password" placeholder="Password" />
              </div>
              <d-button pill :disabled="loading" type="submit" class="btn-accent d-table mx-auto mt-4">Sign in to {{
                $brand.name }}</d-button>
            </template>
            <template v-else-if="method === 'saml'">
              <d-button pill :disabled="loading" type="submit" class="btn-accent d-table mx-auto mt-0">Sign in to {{
                $brand.name }}</d-button>
            </template>
            <template v-else-if="method === 'google'">
              <d-button pill :disabled="loading" type="submit" class="btn-accent d-table mx-auto mt-0">Sign in with
                Google</d-button>
            </template>
            <div class="text-center mt-4 mb-0" :key="method">
              <template v-for="method in otherMethods">
                <div :key="method">
                  <d-link
                    :href="after ? `/login?method=${method}&authSlug=${slug}&after=${after}` : `/login?method=${method}&authSlug=${slug}`"
                    class="other-link">
                    sign in with {{ method }}
                  </d-link>
                </div>
              </template>
            </div>
          </d-form>
        </d-card-body>
      </d-card>
      <div v-if="pwaEnabled && !isPWA" class="mt-4 text-center">
        <d-link href='#' @click.native.stop="onClickInstall" class="other-link">Install {{ $brand.name }} Desktop
          App</d-link>
      </div>
    </d-col>
  </d-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/lib/api'
import axios from 'axios'
import auth from '@/lib/auth'
import config from '@/config'
import { consoleId } from '@/lib/console'

const methodPriority = [
  'key',
  'saml',
  'google',
  'password'
]

export default {
  name: 'login-dialog',
  data () {
    return {
      credentials: {
        email: '',
        password: '',
        key: ''
      },
      after: null,
      auth: null,
      error: null,
      pwaEnabled: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'loading',
      'key',
      'redirectError',
      'redirectFail'
    ]),
    ...mapGetters([
      'slug'
    ]),
    ...mapGetters('pwa', [
      'canInstallApp',
      'isPWA'
    ]),
    otherMethods () {
      return methodPriority
        .filter(method => method !== this.method && (this.auth || {})[method])
    }
  },
  async mounted () {
    try {
      const { method, after } = this.$route.query
      if (this.key) {
        this.method = 'key'
        this.credentials.key = this.key
        return this.login()
      }
      const { data } = await api.get('/auth/config', { params: { authSlug: this.slug } })
      this.auth = data
      if (method) {
        this.method = method
      }
      this.after = after
      // If this is the badge admin app, we allow login with a key in addition to
      // whatever login mechanism the auth-config prescribes (for admins) above.
      if (!this.method) {
        for (const method of methodPriority) {
          if (this.auth[method]) {
            this.method = method
            break
          }
        }
      }
      // Check if PWA is enabled by fetching manifest
      try {
        const { data } = await axios.get(`${config.api.server}/manifest.json?consoleId=${consoleId()}&hostname=${window.location.hostname}`)
        this.pwaEnabled = !!data && data.start_url
      } catch (error) {
        console.log(error, 'manifest_fetch_failed')
      }
      if (!this.method) {
        throw new Error('no valid login methods found')
      }
      if (this.redirectError) {
        this.error = this.genericError()
      } else if (this.redirectFail) {
        this.error = this.authError()
      }
    } catch (error) {
      this.error = this.genericError(error)
    }
  },
  methods: {
    ...mapActions('pwa', [
      'showInstallPrompt'
    ]),
    async login () {
      try {
        await auth.login(this.method, { ...this.credentials, after: this.after })
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.error = this.authError(error)
        } else if (error.response && error.response.status === 429) {
          this.error = this.tooManyAttemptsError(error)
        } else {
          this.error = this.genericError(error)
        }
      }
    },
    authError (error) {
      if (error) console.log(error)
      return { message: 'Invalid login. Please try again.' }
    },
    tooManyAttemptsError (error) {
      if (error) console.log(error)
      return { message: 'Too many login attempts. Please contact support or try again later.' }
    },
    genericError (error) {
      console.log(error)
      return { message: 'Sorry, there was a problem. Please try signing in later.' }
    },
    onClickInstall () {
      if (!this.canInstallApp) {
        const isIOS = this.$browserDetect.isIOS || this.$browserDetect.isChromeIOS
        if (this.$browserDetect.isEdge || (this.$browserDetect.isChrome && !this.$browserDetect.isOpera && !isIOS)) {
          alert(`Cannot install app. Your may already have an app installed. Contact ${this.$brand.company} support for additional help`)
        } else if (isIOS) {
          alert(`At the moment, installation is not supported on mobile devices or tablets. Contact ${this.$brand.company} support for additional help`)
        } else {
          alert(`At the moment, installation requires a Chrome or Edge browser. Contact ${this.$brand.company} support for additional help`)
        }
        this.showInstallHelp = true
      } else {
        this.showInstallPrompt()
      }
    }
  }
}
</script>

<style scoped>
  .other-link {
    color: #818EA3;
    font-size: 12px;
    text-decoration: underline;
  }
</style>
