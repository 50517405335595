import api from '@/lib/api'

// initial state
const state = {
  recipients: [],
  addresses: {},
  contacts: {},
  loading: false
}

// getters
const getters = {
  recipients: state => state.recipients,
  addresses: state => state.addresses,
  contacts: state => state.contacts,
  loading: state => state.loading
}

// actions
const actions = {
  refresh: async ({ commit }) => {
    try {
      commit('SET_LOADING', true)
      const { data: recipients } = await api.get('orders/recipients')
      if (recipients && recipients.length) {
        commit('SET_RECIPIENTS', recipients)
      } else {
        throw new Error('Failed to fetch order recipient data')
      }
      const { data: addresses } = await api.get('orders/addresses')
      if (addresses && addresses.length) {
        const addressMap = addresses.reduce((ob, a) => {
          ob[a.id] = a
          return ob
        }, {})
        commit('SET_ADDRESSES', addressMap)
      } else {
        throw new Error('Failed to fetch order recipient data')
      }
      const { data: contacts } = await api.get('orders/contacts')
      if (contacts && contacts.length) {
        const contactsMap = contacts.reduce((ob, a) => {
          ob[a.id] = a
          return ob
        }, {})
        commit('SET_CONTACTS', contactsMap)
      } else {
        throw new Error('Failed to fetch order recipient data')
      }
    } catch (err) {
      console.warn(`${err} when loading order recipient data`)
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

// mutations
const mutations = {
  SET_RECIPIENTS (state, recipients) {
    state.recipients = recipients
  },
  SET_ADDRESSES (state, addresses) {
    state.addresses = addresses
  },
  SET_CONTACTS (state, contacts) {
    state.contacts = contacts
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
