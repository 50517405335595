<script>
import {
  optionsMerger,
  propsBinder,
  findRealParent,
  ImageOverlayMixin,
  OptionsMixin
} from 'vue2-leaflet'
import { DomEvent } from 'leaflet'
import 'leaflet-toolbar'
import 'leaflet-distortableimage'

const L = window.L

export default {
  name: 'LDistortableImageOverlay',
  mixins: [ImageOverlayMixin, OptionsMixin],
  props: {
    actions: {
      type: Array,
      custom: true,
      default: () => ([
        L.FreeRotateAction,
        L.ScaleAction,
        L.RotateAction,
        L.DragAction,
        L.DistortAction,
        L.OpacityAction,
        L.OpacitiesAction,
        L.BorderAction,
        L.LockAction
      ])
    },
    corners: {
      type: Array,
      custom: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    fullResolutionSrc: {
      type: String
    },
    mode: {
      type: String,
      default: 'freeRotate'
    },
    rotation: {
      type: Object
    },
    selected: {
      type: Boolean,
      default: false
    },
    suppressToolbar: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      custom: true,
      default: 1.0
    },
    alt: {
      type: String,
      default: ''
    },
    interactive: {
      type: Boolean,
      default: false
    },
    crossOrigin: {
      type: Boolean,
      default: false
    },
    errorOverlayUrl: {
      type: String,
      custom: true,
      default: ''
    },
    zIndex: {
      type: Number,
      custom: true,
      default: 100
    },
    className: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.distortableImageOverlayOptions = {
      ...this.layerOptions,
      ...this.interactiveLayerOptions,
      opacity: this.opacity,
      alt: this.alt,
      interactive: this.interactive,
      crossOrigin: this.crossOrigin,
      errorOverlayUrl: this.errorOverlayUrl,
      zIndex: this.zIndex,
      className: this.className,
      actions: this.actions,
      corners: this.corners,
      editable: this.editable,
      fullResolutionSrc: this.fullResolutionSrc,
      mode: this.mode,
      rotation: this.rotation,
      selected: this.selected,
      suppressToolbar: this.suppressToolbar
    }
    const options = optionsMerger(this.distortableImageOverlayOptions, this)
    this.mapObject = L.distortableImageOverlay(this.url, options)
    DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, this.$options.props)
    this.parentContainer = findRealParent(this.$parent)
    this.parentContainer.addLayer(this, !this.visible)
    this.$nextTick(() => {
      this.$emit('ready', this.mapObject)
    })
  },
  methods: {
    getCorners () {
      return this.mapObject.getCorners()
    },
    getGeometry () {
      const corners = this.getCorners()
      return {
        type: 'Polygon',
        coordinates: [[
          [corners[0].lng, corners[0].lat],
          [corners[1].lng, corners[1].lat],
          [corners[3].lng, corners[3].lat],
          [corners[2].lng, corners[2].lat],
          [corners[0].lng, corners[0].lat]
        ]]
      }
    }
  },
  render () {
    return null
  }
}
</script>

<style>
.leaflet-toolbar-icon-vertical {
   display: block !important;
   width: 30px !important;
   height: 30px !important;
   line-height: 30px !important;
   padding: 0 !important;
   text-align: center !important;
   text-decoration: none !important;
   background-color: #fff;
   border: inset 0.5px lightgray !important;
   font-size: 12px !important;
   font-weight: bold !important;
   color:#0087A8 !important;
   float: none !important;
   margin: auto !important;
   z-index:900;
 }

 .leaflet-toolbar-1 li:last-child a {
   border-radius: 0px 0px 4px 4px !important;
 }
</style>
