<template>
  <div class="py-2 mb-4">
    <multiselect ref="vms"
      :value="value"
      :options="searchOptions"
      :multiple="true"
      :group-label="groupLabel"
      :group-values="groupValues"
      :group-select="groupSelect"
      :searchable="searchable"
      :max-suggestions="10"
      label="title"
      @select="onSelect"
      @remove="onRemove"
      :internal-search="false"
      :placeholder="placeholder"
      tagPosition="bottom"
      openDirection="below"
      select-label=""
      @search-change="onSearch">
      <template slot="noResult">No matches found</template>
      <template slot="noOptions">
        <div v-if="!selections.length && !!importFn">
          ...or <d-link class="" @click.native.stop="onImport">import from csv file</d-link>
        </div>
        <div else></div>
      </template>
    </multiselect>
    <input ref="upload" type="file" accept=".csv" hidden @change="csvImport" />
  </div>
</template>

<script>
export default {
  name: 'suggest-multi',
  props: ['value', 'options', 'groupLabel', 'groupValues', 'groupSelect', 'searchable', 'placeholder', 'importFn', 'importLabel', 'importTooltip'],
  data () {
    return {
      searchOptions: [],
      selections: []
    }
  },
  mounted () {
    if (typeof this.searchable !== 'boolean') {
      this.searchable = true
    }
    if (this.options && this.options.length) {
      this.searchOptions = this.options.slice(0, 10)
    }
    this.selections = this.value
  },
  methods: {
    async onSearch (query) {
      if (typeof this.options === 'function') {
        this.searchOptions = await this.options(query)
      } else if (this.options && this.options.length) {
        this.searchOptions = this.options.length < 10 ? this.options : this.options.filter(o => o.title.includes(query))
      }
    },
    onSelect (selection) {
      selection = selection.length ? selection : [selection]
      const newSelections = selection.filter(item => !this.selections.find(s => s.id === item.id))
      if (newSelections.length) {
        this.selections = this.selections.concat(newSelections)
        this.$emit('change', this.selections)
      }
    },
    onRemove (selection) {
      selection = selection.length ? selection : [selection]
      for (const item of selection) {
        const idx = this.selections.findIndex(s => s.id === item.id)
        if (idx >= 0) {
          this.selections.splice(idx, 1)
        }
      }
      this.$emit('change', this.selections)
    },
    onImport () {
      this.$refs.upload.click()
    },
    async csvImport ({ target }) {
      try {
        this.progress = {
          step: 'Processing file',
          cancel: false
        }
        this.selections = await this.importFn({ target })
        this.$emit('change', this.selections)
      } catch (error) {
        this.progress = null
        await this.delay(100)
        console.log(error, 'csv_parse_error')
        if (error.response) {
          const { data } = error.response
          this.myNotice = {
            theme: 'danger',
            message: `Could not load file: ${data.reason || data.message || 'Sorry, there was a problem. Please ensure CSV contains name and personID columns'}`
          }
        } else {
          this.myNotice = {
            theme: 'danger',
            message: 'Sorry, there was a problem. Please ensure CSV format containing name, personID columns'
          }
        }
      } finally {
        target.value = ''
      }
    },
    clearAll () {
      this.value = []
      this.selections = []
      this.$emit('change', this.selections)
    }
  }
}
</script>

<style>
.multiselect__tag {
  background-color: #eeeeee;
  color: #5a6169;
  font-size: 16px;
}
.multiselect__tag-icon:hover {
  background-color: #5a6169;
}
.multiselect__option--highlight {
  background-color: #007bff;
}

.multiselect__option--highlight:after {
  background-color: #007bff;}
</style>
