<template>
  <d-modal v-if="showModal" @close="close" disabled class="edit-modal">
    <d-modal-header v-if="title">
      <d-modal-title>{{ title }}</d-modal-title>
    </d-modal-header>
    <!-- Form Fields -->
    <d-form @keydown.enter.stop.prevent @submit.stop.prevent="save">
      <d-tabs v-if="tabs" card pills vertical>
        <d-tab v-for="tab in tabs" :key="tab.title" :title="tab.title">
          <edit-field v-for="field in tab.fields" :field="field" :key="field.id || field.name" :ref="field.ref" />
        </d-tab>
      </d-tabs>
      <d-modal-body v-else>
        <div v-if="customNote" v-html="customNote"></div>
        <edit-field v-for="field in fields" :field="field" :key="field.id || field.name" :ref="field.ref"  />
      </d-modal-body>
      <div class="form-group mb-3 d-table mx-auto">
      </div>
      <div v-if="submitting" class="d-table mx-auto edit-buttons text-right">
        <d-button disabled theme="light">
          <i class="fas fa-sync fa-spin mr-1"></i> Please wait...
        </d-button>
      </div>
      <div v-else class="d-table mx-auto edit-buttons text-right">
        <d-button @click.stop.prevent="cancel" class="mr-4" theme="light">
          {{ 'Cancel' }}
        </d-button>
        <d-button type="submit" :theme="submitTheme">
          {{ label || 'Submit' }}
        </d-button>
      </div>
    </d-form>
  </d-modal>
</template>

<script>
import EditField from '@/components/EditField'

export default {
  name: 'edit-modal',
  props: [],
  data () {
    return {
      key: String(Math.random()),
      showModal: false,
      title: null,
      label: null,
      submitTheme: null,
      customNote: null,
      fields: [],
      tabs: null,
      submitting: false,
      onSubmit: () => {},
      onCancel: () => {},
      onClose: () => {}
    }
  },
  components: {
    EditField
  },
  methods: {
    open ({ title, label, submitTheme, customNote, fields, onSubmit, onClose, onCancel }) {
      this.title = title
      this.label = label
      this.submitTheme = submitTheme || 'primary'
      this.customNote = customNote
      if (Array.isArray(fields)) {
        this.fields = fields
      } else {
        this.tabs = []
        Object.entries(fields).forEach(([title, fields]) => {
          this.tabs.push({ title, fields })
          this.fields.push(...fields)
        })
      }
      this.onSubmit = onSubmit || this.onSubmit
      this.onClose = onClose || this.onClose
      this.onCancel = onCancel || this.onCancel
      this.key = String(Math.random())
      this.showModal = true
    },
    close () {
      if (this.showModal) {
        this.fields = []
        this.tabs = null
        this.showModal = false
        this.onClose()
      }
    },
    save () {
      try {
        const values = {}
        this.fields.filter(f => f.id).forEach(f => { values[f.id] = f.value })
        const result = this.onSubmit(values)
        if (result && typeof result.then === 'function') {
          this.submitting = true
          result
            .then(() => this.close())
            .catch((err) => alert(err.message))
            .finally(() => { this.submitting = false })
        } else {
          this.close()
        }
      } catch (err) {
        alert(err.message)
      }
    },
    cancel () {
      this.onCancel()
      this.close()
    },
    clearComponent (ref) {
      this.$refs[ref][0].clearAll()
    }
  }
}
</script>

<style>
.edit-modal .modal-content {
  background-color: #ffffffff;
}
.edit-modal.modal {
  z-index: 5000;
}
.edit-buttons {
  table-layout: fixed;
  width: 100%;
  padding-right: 30px;
  padding-bottom: 30px;
}
.edit-modal .nav-pills {
  background-color: rgba(90, 97, 105, 0.06)
}
</style>
