<template>
  <div style="white-space: nowrap;" class="pl-2">
    <span v-if="label" class="mr-1">
      <i v-if="!hideIcon" class="far fa-calendar-alt fa-lg mr-1" />
      {{ ` ${label} ` }}
    </span>
    <span v-else-if="color" class="">
      <i v-if="color" :class="`text-${color} fas fa-circle fa-sm mr-1`" />
    </span>
    <v-md-date-range-picker
      :class="klass"
      :style="style"
      :opens="opens"
      @change="dateRangeChange"
      :presets="presets"
      :start-date="range.start"
      :end-date="range.end.clone().subtract(1, 'day')"
      :showActivatorLabel="false"
      :showActivatorBar="false">
      <d-form-input slot="input" placeholder="Select Date Range"
        @change="inputChange"
        :value="formattedDateRange">
      </d-form-input>
    </v-md-date-range-picker>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
import moment from 'moment/src/moment'

export default {
  name: 'date-picker',
  props: ['name', 'value', 'label', 'extraPresets', 'replacementPresets', 'defaultRange', 'hideIcon', 'noSave', 'opens', 'fill', 'color', 'formatDateRange'],
  mixins: [utils],
  data () {
    return {
      range: {
        start: moment().subtract(1, 'days'),
        end: moment()
      }
    }
  },
  mounted () {
    // set the position of the calendar
    // was previously defaulted to "right", so keep that behavior
    // https://ly525.github.io/material-vue-daterange-picker/examples.html#position

    try {
      if (this.defaultRange) {
        this.range = this.defaultRange
      }
      if (this.value) {
        this.range = {
          start: moment(this.value.start),
          end: moment(this.value.end)
        }
      } else {
        let rangeSetting
        if (!this.noSave) {
          rangeSetting = this.loadSetting(`${this.name}.range`)
        }
        if (rangeSetting) {
          const range = JSON.parse(rangeSetting)
          if (range.start && range.end) {
            this.range.start = moment(range.start)
            this.range.end = moment(range.end)
          }
        }
      }
    } catch (error) {
      console.log(error)
      this.range = this.defaultRange
    }
    this.dateRangeChange()
  },
  computed: {
    formattedDateRange () {
      const start = this.range.start
      const end = this.range.end.clone().subtract(1, 'day')
      const preset = this.presets.find(p => p.range[0].isSame(start, 'day') && p.range[1].isSame(end, 'day'))
      if (this.formatDateRange) {
        const formatted = this.formatDateRange({ start, end, preset })
        if (formatted) {
          return formatted
        }
      }
      if (preset) {
        return preset.label
      }
      if (start.isSame(end, 'day')) {
        return start.format('L')
      }
      return `${start.format('L')} - ${end.format('L')}`
    },
    presets () {
      return this.replacementPresets
        ? this.replacementPresets
        : [{
          label: 'Today',
          range: [moment(), moment()]
        }, {
          label: 'Last 7 days',
          range: [moment().subtract(7, 'day'), moment()]
        }, {
          label: 'Last 30 days',
          range: [moment().subtract(30, 'day'), moment()]
        }, {
          label: 'This Week',
          range: this.getRange(0, 0, 'week')
        }, {
          label: 'Last Week',
          range: this.getRange(1, 1, 'week')
        }, {
          label: 'This Month',
          range: this.getRange(0, 0, 'month')
        }, {
          label: 'Last Month',
          range: this.getRange(1, 1, 'month')
        }, {
          label: 'This Year',
          range: this.getRange(0, 0, 'year')
        }, {
          label: 'Last Year',
          range: this.getRange(1, 1, 'year')
        },
        ...(this.extraPresets ? this.extraPresets : [])]
    },
    klass () {
      if (this.fill) {
        return 'w-100'
      }
      return this.label ? '' : 'w-75'
    },
    style () {
      if (this.fill) {
        return ''
      }
      return this.label ? 'width: 70%;' : ''
    }
  },
  methods: {
    dateRangeChange (range) {
      if (range) {
        const [start, end] = range
        this.range = { start: start.clone(), end: end.clone().add(1, 'day') }
      }
      this.range = this.snapToStartOfDay(this.range)
      if (this.range && this.range.start && this.range.end) {
        if (!this.noSave) {
          this.saveSetting(`${this.name}.range`, JSON.stringify({
            start: this.range.start.toISOString(),
            end: this.range.end.toISOString()
          }))
        }
        this.$emit('dateRangeChanged', { start: moment(this.range.start), end: moment(this.range.end) })
      }
    },
    inputChange (value) {
      let [start, end] = value.split('-')
      start = start.trim()
      end = end.trim()
      this.dateRangeChange([moment(start), moment(end)])
    },
    snapToStartOfDay (range) {
      if (range) {
        const newRange = {}
        if (range.start) {
          newRange.start = range.start.startOf('day')
        }
        if (range.end) {
          newRange.end = range.end.startOf('day')
        }
        return newRange
      }
      return range
    },
    getRange (startOffset = 0, endOffset = 0, period = 'day') {
      return [
        moment().subtract(startOffset, period).startOf(period),
        moment().subtract(endOffset, period).endOf(period)
      ]
    }
  }
}
</script>
