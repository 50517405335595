import { detectDomain } from '@/lib/util'

const detectedDomain = detectDomain() || 'strongline'
const installRejectedKey = `${window.location.hostname}:${detectedDomain}:pwa:install_reject`

// initial state
const state = {
  beforeInstallPromptEvent: null,
  appInstalled: false,
  installOutcome: null,
  isPWA: false
}

// getters
const getters = {
  canInstallApp: state => state.beforeInstallPromptEvent != null,
  installPrompt: state => state.beforeInstallPromptEvent,
  appInstalled: state => state.appInstalled,
  isPWA: state => state.isPWA,
  installRejected: () => localStorage.getItem(installRejectedKey) === 'true'
}

// actions
const actions = {
  setup: ({ commit, state }) => {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      commit('SET_BEFORE_INSTALL_PROMPT_EVENT', e)
      console.log('beforeinstallprompt event handled')
    })
    window.addEventListener('appinstalled', e => {
      console.log('appinstalled event handled')
      commit('SET_BEFORE_INSTALL_PROMPT_EVENT', null)
      commit('SET_APP_INSTALLED', true)
    })
    commit('SET_IS_PWA', window.matchMedia('(display-mode: minimal-ui)').matches)
    window.matchMedia('(display-mode: minimal-ui)').addEventListener('change', (evt) => {
      let displayMode = 'browser'
      if (evt.matches) {
        displayMode = 'minimal-ui'
      }
      console.log(`displayMode changed to ${displayMode}`)
      commit('SET_IS_PWA', displayMode === 'minimal-ui')
    })
  },
  showInstallPrompt: async ({ commit, getters }) => {
    let installOutcome = false
    try {
      if (!getters.canInstallApp) throw new Error('Install not available')
      getters.installPrompt.prompt()
      const { outcome } = await getters.installPrompt.userChoice
      installOutcome = outcome
      commit('SET_BEFORE_INSTALL_PROMPT_EVENT', null)
    } catch (error) {
      console.log(error)
    } finally {
      commit('SET_INSTALL_OUTCOME', installOutcome)
    }
  },
  recordInstallSkip: async ({ state }) => {
    try {
      localStorage.setItem(installRejectedKey, 'true')
    } catch (error) {
      console.log(error, 'record_install_skip_failed')
    }
  }
}

// mutations
const mutations = {
  SET_BEFORE_INSTALL_PROMPT_EVENT (state, e) {
    state.beforeInstallPromptEvent = e
  },
  SET_APP_INSTALLED (state, installed) {
    state.appInstalled = installed
  },
  SET_INSTALL_OUTCOME (state, outcome) {
    state.installOutcome = outcome
  },
  SET_IS_PWA (state, isPWA) {
    state.isPWA = isPWA
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
