<template>
  <d-modal v-if="showModal" @close="close" disabled class="url-pattern-modal">
    <d-modal-header v-if="title">
      <d-modal-title>{{ title }}</d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <div>{{ help }}</div>
      <div class="form-group my-3">
        <div id="param-source">
          <d-select v-if="source.options" v-model="source.value" :options="source.options" @change="copied = false"/>
          <d-input v-else :type="source.type" v-model="source.value" />
        </div>
        <div class="input-group my-4">
          <d-input id="url-text" readonly :value="url" type="text" />
          <d-button class="btn input-group-append" type="button" @click="copyUrl"><i class="fa fa-copy" /></d-button>
        </div>
        <div class="text-center text-white bg-primary py-1" v-if="copied">Copied!</div>
      </div>
    </d-modal-body>
  </d-modal>
</template>

<script>
import { write } from 'clipboardy'

export default {
  name: 'url-pattern-modal',
  props: [],
  data () {
    return {
      showModal: false,
      source: {},
      urlPrefix: '',
      title: '',
      help: '',
      copied: false
    }
  },
  computed: {
    url () {
      return this.urlPattern.replace(/%s/, this.source.value)
    }
  },
  methods: {
    open ({ title, help, source, urlPattern }) {
      this.title = title
      this.help = help
      this.source = source
      this.urlPattern = urlPattern
      this.showModal = true
    },
    close () {
      if (this.showModal) {
        this.showModal = false
        this.source = {}
        this.title = ''
        this.help = ''
        this.urlPattern = ''
        this.copied = false
      }
    },
    async copyUrl () {
      await write(this.url)
      this.copied = true
    }
  }
}
</script>

<style>
.url-pattern-modal .modal-content {
  background-color: #ffffffff;
  min-width: 640px
}
</style>
