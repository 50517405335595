<template>
  <div>
    <l-control v-if="visible" :position="position" class="leaflet-bar leaflet-control">
      <a href="#" @click="onToggleMode('markers')" title="Edit markers" :class="editMarkers && 'on'">
        <i class="fa fa-map-marker-alt" />
      </a>
      <a href="#" @click="onToggleMode('units')" title="Edit units" :class="editUnits && 'on'">
        <i class="fa fa-vector-square" />
      </a>
    </l-control>
    <l-feature-group ref="toolbar">
      <l-popup :options="{closeButton:false, offset:[0,0]}">
        <d-button-toolbar>
          <d-button-group size="small">
            <d-button theme="light" @click="toolbarAction('edit')"><i class="fa fa-pencil-alt" /></d-button>
            <d-button theme="light" @click="toolbarAction('delete')"><i class="fa fa-trash-alt" /></d-button>
          </d-button-group>
        </d-button-toolbar>
      </l-popup>
    </l-feature-group>
  </div>
</template>

<script>
import {
  findRealParent,
  LControl,
  LFeatureGroup,
  LPopup
} from 'vue2-leaflet'
import 'leaflet-draw'
import centroid from '@turf/centroid'

const L = window.L

const staticStyle = {
  color: '#17c671',
  fillColor: '#17c671',
  fillOpacity: 0.2,
  opacity: 0.3,
  weight: 2
}

const staticInactiveStyle = {
  color: '#3d5170',
  fillColor: '#3d5170',
  fillOpacity: 0.2,
  opacity: 0.3,
  weight: 2
}

const unselectedStyle = {
  color: '#3388ff',
  fillColor: '#88ffff',
  fillOpacity: 0.2,
  opacity: 0.3,
  weight: 2
}

const unselectedInactiveStyle = {
  color: '#3388ff',
  fillColor: '#3388ff',
  fillOpacity: 0.2,
  opacity: 0.3,
  weight: 2
}

const selectedStyle = {
  color: '#c4183c',
  fillColor: '#c4183c',
  fillOpacity: 0.2,
  opacity: 0.3,
  weight: 2
}

const background = {
  type: 'Feature',
  properties: { id: 'background' },
  geometry: {
    type: 'Polygon',
    coordinates: [[[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]]
  }
}

const backgroundStyle = {
  fillOpacity: 0,
  opacity: 0
}

export default {
  props: {
    position: {
      type: String,
      default: 'topleft'
    },
    visible: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: ''
    },
    units: {
      type: Array
    }
  },
  components: {
    LControl,
    LFeatureGroup,
    LPopup
  },
  data () {
    return {
      state: {
        mode: ''
      },
      selected: null,
      draw: null
    }
  },
  watch: {
    mode () {
      this.setMode(this.mode)
      this.updateStyles()
    },
    units () {
      this.updatedUnits()
    },
    selected (selected, oldSelected) {
      if (selected !== oldSelected) {
        this.saveChanges(oldSelected)
      }
      this.updateStyles()
    }
  },
  beforeDestroy () {
    this.draw.disable()
    this.features.remove()
  },
  async mounted () {
    this.map = findRealParent(this.$parent).mapObject
    this.features = new L.FeatureGroup().addTo(this.map)
    this.draw = new L.Draw.Polygon(this.map)
    await this.updatedUnits()
    this.map.on(L.Draw.Event.CREATED, (event) => {
      this.draw.disable()
      this.$emit('add:unit', event.layer.toGeoJSON().geometry)
    })
    this.map.on(L.Draw.Event.EDITVERTEX, (e) => {
      const { properties } = (e.poly && e.poly.feature) || {}
      const { id } = properties || {}
      this.edited = id
      this.toolbarClose()
    })
    this.features.on('mouseover', (e) => {
      if (this.editUnits) return
      const { layer } = e
      const { id } = (layer.feature || {}).properties || {}
      if (id === 'background') {
        this.selected = null
      } else {
        this.selected = this.features.getLayerId(layer)
      }
      this.updateStyles()
    })
    this.features.on('mouseup', (e) => {
      if (!this.editUnits) return
      const { layer } = e
      const { id } = (layer.feature || {}).properties || {}
      if (id === 'background') {
        this.selected = null
      } else {
        layer.editing.enable()
        const previousSelection = this.selected
        this.selected = this.features.getLayerId(layer)
        if (previousSelection === this.selected) {
          setTimeout(() => this.toolbarOpen(), 1)
        }
      }
      this.updateStyles()
    })
  },
  computed: {
    editMarkers () {
      return this.state.mode.startsWith('markers')
    },
    editUnits () {
      return this.state.mode.startsWith('units')
    }
  },
  methods: {
    async updatedUnits () {
      this.features.clearLayers()
      this.units.forEach(unit => {
        const config = unit.config || {}
        const { geometry, state } = config
        if (geometry && geometry.type === 'Polygon') {
          try {
            const geoJSON = {
              type: 'Feature',
              properties: { id: unit.id, active: state === 'active' },
              geometry
            }
            L.geoJSON(geoJSON).eachLayer(layer => {
              this.features.addLayer(layer)
              layer.bindTooltip(`<div class="unit-label">${unit.name}</div>`, { closeButton: false, offset: [0, 0] }
              )
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
      L.geoJSON(background).eachLayer(layer => layer.addTo(this.features).bringToBack())
      this.updateStyles()
    },
    updateStyles () {
      this.features.eachLayer((layer) => {
        const layerId = this.features.getLayerId(layer)
        const properties = layer.feature.properties || {}
        if (properties.id === 'background') {
          layer.setStyle(backgroundStyle)
        } else if (!this.editUnits) {
          layer.setStyle(properties.active ? staticStyle : staticInactiveStyle)
          layer.editing.disable()
          if (this.selected === layerId) {
            layer.setStyle(unselectedInactiveStyle)
          }
        } else if (this.selected === layerId) {
          layer.setStyle(selectedStyle)
        } else {
          layer.setStyle(properties.active ? unselectedStyle : unselectedInactiveStyle)
          layer.editing.disable()
        }
      })
    },
    saveChanges (selected) {
      if (!selected) return
      selected = this.features.getLayer(selected)
      if (selected) {
        selected.editing.disable()
        if (this.edited) {
          if (confirm('Save changes?')) {
            const unit = this.units.find(u => u.id === this.edited)
            if (unit) {
              this.$emit('update:unit', { id: unit.id, geometry: selected.toGeoJSON().geometry })
            }
          } else {
            this.updatedUnits()
          }
          this.edited = null
        }
      }
    },
    onToggleMode (mode) {
      this.saveChanges(this.selected)
      if (this.state.mode === mode) {
        this.state.mode = ''
      } else {
        this.state.mode = mode
      }
      this.$emit('change:mode', this.state.mode)
    },
    toggleDrawMode () {
      if (this.draw.enabled()) {
        this.draw.disable()
      } else {
        this.draw.enable()
      }
    },
    setMode (mode) {
      this.state.mode = this.mode
    },
    toolbarOpen () {
      this.saveChanges(this.selected)
      const feature = this.getSelectedFeature()
      if (!feature) return
      const { geometry } = centroid(feature)
      if (geometry && geometry.coordinates) {
        const [lng, lat] = geometry.coordinates
        const toolbar = this.$refs.toolbar
        if (toolbar) {
          toolbar.mapObject.openPopup({ lat, lng })
        }
      }
    },
    toolbarClose () {
      const toolbar = this.$refs.toolbar
      if (toolbar) {
        toolbar.mapObject.closePopup()
      }
    },
    toolbarAction (action) {
      this.toolbarClose()
      const feature = this.getSelectedFeature()
      if (!feature) return
      const unit = this.units.find(u => u.id === feature.properties.id)
      if (!unit) return
      if (action === 'edit') {
        this.$emit('edit:unit', unit.id)
      } else if (action === 'delete') {
        this.$emit('delete:unit', { id: unit.id })
      }
    },
    getSelectedFeature () {
      if (!this.selected) return
      const layer = this.features.getLayer(this.selected)
      if (!layer) return
      const { feature } = layer
      if (feature && feature.geometry && feature.properties && feature.properties.id) {
        return feature
      }
    },
    prepareToExit (next) {
      this.saveChanges(this.selected)
      next()
    }
  }
}
</script>

<style scoped>
a.on {
  background-color: #007bff;
}

a.on i.fa {
  color: white;
}
</style>

<style>
.unit-label {
  text-align: center;
  font-weight: 900;
  padding: 5px;
  margin: 0;
}
</style>
