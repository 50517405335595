<template>
  <d-container fluid class="kiosk d-flex flex-column p-4">
    <d-row class="kiosk-header mb-4">
      <d-col md="4" class="d-flex justify-content-start">
        <d-link v-if="canExitKiosk()" @click.native.link="exitToDashboard()">
          <p><i class="fa fa-external-link-alt mr-1" />Exit to Dashboard</p>
        </d-link>
        <d-link v-else-if="canLogoutKiosk()" @click.native.link="logoutKiosk()">
          <p><i class="fa fa-external-link-alt mr-1" />Exit Dashboard</p>
        </d-link>
      </d-col>
      <d-col md="4" class="d-flex justify-content-center">
        <img id="main-logo" :src="`/img/${$brand.domain}/logo.png`" :alt="$brand.name">
      </d-col>
      <d-col md="4" class="text-light">
        <div class="d-flex justify-content-end time my-auto">
          <div v-if="clockTime" class="text-light">
            {{ clockTime }}
          </div>
          <div :class="disconnected ? 'text-danger' : 'text-success'">
            <i class="fa fa-wifi ml-2" />
          </div>
        </div>
      </d-col>
    </d-row>
    <d-row class="flex-fill">
      <d-col>
        <!-- Content -->
        <slot />
      </d-col>
    </d-row>
    <d-row class="kiosk-footer align-items-end">
      <d-col class="d-flex justify-content-center text-center">
        <div v-if="stationHelp" class="text-light">
          <div class="help-content">
            <div>Need Help? {{ stationHelp }}</div>
          </div>
        </div>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import provision from '@/mixins/provision'
import utils from '@/mixins/utils'
import moment from 'moment/src/moment'

export default {
  mixins: [provision, utils],
  data () {
    return {
      clockInterval: null,
      clockTime: null
    }
  },
  created () {
    this.updateClock()
    this.clockInterval = setInterval(() => {
      this.updateClock()
    }, 1000)
  },
  destroyed () {
    clearInterval(this.clockInterval)
  },
  computed: {
    ...mapGetters([
      'connected',
      'slug'
    ]),
    ...mapGetters('pwa', [
      'isPWA'
    ]),
    ...mapGetters('auth', [
      'user',
      'key'
    ]),
    disconnected () {
      return !this.connected
    }
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    updateClock () {
      // this.clockTime = moment().format('ddd Do, LT')
      this.clockTime = moment().format('LT')
    },
    canExitKiosk () {
      const canExit = this.loadSetting(`${this.stationId}-can-exit`)
      return this.isPWA && canExit
    },
    exitToDashboard () {
      // Let's clear the session cookie about an exitable kiosk first
      this.removeSetting(`${this.stationId}-can-exit`)
      // Now reload window at origin with saved slug and no assumed role.
      const { origin } = document.location
      const urlElement = document.createElement('a')
      urlElement.href = origin
      this.loginToUrlWithRole(urlElement, this.slug, null, false)
    },
    canLogoutKiosk () {
      const distroRole = ((this.user || {}).role || '') === 'distribution'
      const kioskLogoutEnabled = ((this.user.zone.config || {}).featureFlags || {}).kioskLogout
      return kioskLogoutEnabled && distroRole && !this.key
    },
    logoutKiosk () {
      this.logout()
    }
  }
}
</script>

<style scoped>
  #main-logo {
    max-width: 300px;
  }
  .help-title {
    font-size: 14px;
  }
  .help-content {
    font-size: 14px;
  }
  .time {
    font-size: 18px;
  }
  .kiosk {
    min-height:100vh;
  }
  .kiosk-header {
    height: 50px;
  }
  .kiosk-footer {
    height: 50px;
  }
  .flex-fill {
    flex:1 1 auto;
  }
</style>

<style>
.kiosk-prompt {
  font-size: 32px;
}
.kiosk-secondary {
  font-size: 14px;
  margin-top: 50px;
  color: #818ea3;
}
.kiosk-step {
  width: 100%;
  height: 100%;
  padding: 30px 10px 30px 10px;
  border-radius: 20px;
  background-color: white;
}
.kiosk-step img {
  min-width: 50px;
}
.kiosk .vue-simple-suggest {
  width: 100%;
}
.kiosk .vue-simple-suggest .suggest-item {
  font-size: 24px;
}
.kiosk .vue-simple-suggest input {
  font-size: 24px;
  padding: 5px 10px !important;
  color: #3d5170 !important;
  background-color: #fff;
  border: 3px solid rgb(255,146,47) !important;
  border-radius: 40px;
  transition: 180ms box-shadow ease-in-out;
  width: 100% !important;
}
.kiosk .vue-simple-suggest input:focus {
  border-color:rgb(255,146,47);
  box-shadow: 0 0 0 3px rgb(255,146,47);
  outline: 3px solid transparent;
}
.kiosk .btn {
  font-size: 24px;
  padding: 0.75rem 1.5rem;
}
.kiosk .btn-sm {
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.kiosk .highlight {
  color: rgb(255,146,47);
}
.kiosk .alert {
  font-size: 18px;
}
.kiosk #actions {
  padding: 3rem;
}
</style>
