import api from '@/lib/api'
import Vue from 'vue'

const state = {
  asset: null,
  assets: {},
  zones: {},
  loading: false,
  error: null
}

const INCLUDE_LABEL_COUNTS = true

const mutations = {
  SET_ZONES (state, zones) {
    // zones: [{ id, parent, name, level, count, isLeaf }, ...]
    const lookup = new Map()
    for (const zone of zones) {
      lookup.set(zone.id, {
        children: [],
        label: INCLUDE_LABEL_COUNTS && zone.count
          ? `${zone.name} (${zone.count})`
          : zone.name,
        ...zone
      })
    }
    for (const zone of zones) {
      if (lookup.has(zone.parent)) {
        lookup.get(zone.parent).children.push(lookup.get(zone.id))
      }
    }
    for (const zone of zones) {
      const node = lookup.get(zone.id)
      if (node.children.length === 0) {
        node.isLeaf = true
        node.children = {}
      }
    }
    for (const [key, value] of lookup) {
      Vue.set(state.zones, key, value)
    }
  },
  SET_ASSETS (state, assets) {
    for (const asset of assets) {
      Vue.set(state.assets, asset.id, asset)
    }
  },
  SET_ASSET (state, { id, asset }) {
    Vue.set(state.assets, id, asset)
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_ERROR (state, error) {
    state.error = error
  },
  CLEAR_ERROR (state) {
    state.error = null
  },
  CLEAR_ASSET (state, id) {
    delete state.assets[id]
  },
  UPDATE_ASSET (state, { id, asset }) {
    if (state.assets[id]) {
      Vue.set(state.assets, asset.id, asset)
    }
  }
}

const getters = {
  tree: state => id => state.zones[id],
  asset: state => id => {
    if (id) {
      return state.assets[id]
    }
  },
  assets: state => {
    return Array.from(Object.values(state.assets))
  },
  loading: state => state.loading,
  error: state => state.error
}

const actions = {
  fetchZones: async ({ commit }) => {
    const resp = await api.get('assets/zones')
    const zones = resp.data.data
    commit('SET_ZONES', zones)
  },
  fetchAssets: async ({ commit }) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    try {
      const response = await api.get('assets')
      commit('SET_ASSETS', response.data.data)
    } catch (error) {
      commit('SET_ERROR', { message: 'Failed to load asset data' })
      console.error('API request failed:', error)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  fetchAsset: async ({ commit }, id) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    try {
      const response = await api.get(`assets/${id}`)
      commit('SET_ASSET', { id, asset: response.data.data })
    } catch (error) {
      commit('SET_ERROR', { message: 'Failed to update asset.' })
    } finally {
      commit('SET_LOADING', false)
    }
  },
  updateAsset: async ({ commit }, { id, notes, status }) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    try {
      if (!state.assets[id]) {
        throw new Error('Asset not found')
      }
      const response = await api.patch(`assets/${id}`, { notes, status })
      const asset = response.data.data
      if (asset) {
        commit('UPDATE_ASSET', { id, asset })
      }
    } catch (error) {
      commit('SET_ERROR', { message: 'Failed to update asset.' })
      console.error('API request failed:', error)
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
