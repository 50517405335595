<template>
  <l-control :position="position" class="leaflet-bar leaflet-control">
    <a v-for="(floor, index) in floors"
      href="#" @click="onSelect(index)"
      :title="floor.name"
      :class="state.index === index && floors.length > 1 && 'on'"
      :key="floor.id"
    >
      {{ floor.config && (typeof floor.config.number === 'number' ? floor.config.number : '') }}
    </a>
  </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet'

export default {
  props: {
    position: {
      type: String,
      default: 'topleft'
    },
    floors: {
      type: Array
    },
    id: {
      type: String
    }
  },
  components: {
    LControl
  },
  data () {
    return {
      state: {
        index: 0
      }
    }
  },
  watch: {
    id () {
      this.setId(this.id)
    }
  },
  mounted () {
    this.setId(this.id)
  },
  methods: {
    onSelect (index) {
      this.state.index = index
      this.$emit('change', this.floors[index])
    },
    setId (id) {
      if (id) {
        const index = this.floors.findIndex(floor => floor.id === id)
        if (index !== -1) {
          this.state.index = index
        }
      }
    }
  }
}
</script>

<style scoped>
a.on {
  background-color: #007bff;
}

a.on i.fa {
  color: white;
}
</style>
