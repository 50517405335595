<template>
  <d-navbar-nav class="flex-row">
    <li class="nav-item dropdown" v-if="user">
      <a class="nav-link dropdown-toggle text-nowrap px-3" v-d-toggle.user-actions>
        <div class="user-avatar mr-2 d-md-inline-block">
          <i class="avatar fa fa-user-circle align-middle"></i>
        </div>
        <span class="d-none d-md-inline-block user">{{ user.name || user.userId }}</span>
      </a>
      <d-collapse ref="usermenu" id="user-actions" class="dropdown-menu dropdown-menu-small dropdown-menu-right">
        <d-dropdown-item @click.native="logout">
          <div class="user-menu-item d-inline-block"><i class="fa fa-sign-out-alt"></i></div> Logout
        </d-dropdown-item>
        <d-dropdown-item v-if="showExport" @click.native="exportData">
          <div class="user-menu-item d-inline-block"><i class="fa fa-file-export"></i></div> Export data
        </d-dropdown-item>
      </d-collapse>
    </li>
    <d-modal id="login" v-if="showLogin" centered @close="showLogin = false">
      <login-dialog no-redirect @close="showLogin = false" />
    </d-modal>
  </d-navbar-nav>
</template>

<script>
import LoginDialog from '@/components/LoginDialog'
import auth from '@/lib/auth'
import api from '@/lib/api'
import utils from '@/mixins/utils'
import { mapGetters } from 'vuex'

export default {
  mixins: [utils],
  props: {
    logoutRedirect: {
      default: null
    }
  },
  data () {
    return {
      showLogin: false
    }
  },
  components: {
    LoginDialog
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    showExport () {
      return (this.features || {}).export && (this.user || {}).role === 'customerSuperAdmin'
    }
  },
  methods: {
    async logout () {
      auth.logout(this.logoutRedirect)
    },
    async exportData () {
      const { data: url } = await api.get('zones/export')
      this.$refs.usermenu.show = false
      if (url) {
        const parsedUrl = new URL(url)
        const suggestedName = parsedUrl.pathname.split('/').slice(-1)[0]
        const response = await fetch(url)
        const blob = await response.blob()
        if (typeof window.showSaveFilePicker === 'function') {
          try {
            const handle = await window.showSaveFilePicker({ suggestedName })
            const writable = await handle.createWritable()
            await writable.write(blob)
            await writable.close()
          } catch (err) {
            if (err.name !== 'AbortError') {
              console.error(err.name, err.message)
            }
          }
        } else {
          const blobURL = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = blobURL
          a.download = suggestedName
          a.style.display = 'none'
          document.body.append(a)
          a.click()
          setTimeout(() => {
            URL.revokeObjectURL(blobURL)
            a.remove()
          }, 1000)
        }
      }
    }
  }
}
</script>

<style>
  .nav-link:hover {
    cursor: pointer;
  }

  /* IE11 Navbar flex fix. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navbar-nav {
      align-items: stretch !important;
      flex: 1 1 100%;
      flex-flow: row wrap;
    }

    .nav-item.notifications {
      margin-left: auto !important;
    }
  }

  .user {
    padding-top: 10px;
  }

  .avatar {
    font-size: 32px;
  }

  .modal-dialog {
    width: fit-content;
  }

  .modal-content {
    background-color: #ffffff00;
  }

  .user-menu-item {
    width: 18px;
  }
</style>
