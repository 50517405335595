import Snapshotter from '@/lib/snapshotter'
import api from '@/lib/api'
import { consoleId, consoleConfig, sendConsoleBeat } from '@/lib/console'
import store from '@/store'

const defaultOptions = {
  beatInterval: 60000,
  maxShotBufferSize: 100
}

class HeartBeats {
  constructor (options) {
    this.options = { ...defaultOptions, ...options }
    this.started = false
    this.shotBuffer = []
    this.snapshotter = new Snapshotter()
    this.snapshotter.on('snapshot', async (data) => {
      try {
        this.addShot(data)
        while (this.shotBuffer.length) {
          const { id, data, createdAt } = this.shotBuffer[0]
          await api.post(`consoles/${id}/snapshots`, { data, createdAt })
          this.shotBuffer.shift()
        }
      } catch (error) {
        // ignore
      }
    })
  }

  addShot (data) {
    this.shotBuffer.push({ id: consoleId(), data, createdAt: new Date() })
    this.shotBuffer = this.shotBuffer.slice(-this.options.maxShotBufferSize)
  }

  start () {
    if (this.started) return
    this.started = true
    this.beat()
    this.interval = setInterval(() => this.beat(), this.options.beatInterval)
  }

  stop () {
    if (!this.started) return
    this.started = false
    this.snapshotter.stop()
    clearInterval(this.interval)
  }

  async beat () {
    if (store.getters.muted) {
      return
    }
    await sendConsoleBeat()
    consoleConfig()
      .then(({ monitored }) => {
        if (monitored) {
          this.snapshotter.start()
        } else {
          this.snapshotter.stop()
        }
      })
      .catch(console.log)
  }
}

export default HeartBeats
