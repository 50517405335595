<template>
  <div class="input-group">
    <label for="keyphrase" />
    <input class="form-control" type="text" id="keyphrase" :disabled="disabled" :value="generated" />
    <d-button v-if="!disabled" class="input-group-append btn-light" @click.native.prevent="regenerate()"><i class="fa fa-redo ml-1" aria-hidden="true" /></d-button>
  </div>
</template>

<script>
import { collections, objects, predicates, teams } from 'friendly-words'

export default {
  name: 'generator',
  props: {
    value: { type: String },
    label: { type: String },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      generated: ''
    }
  },
  mounted () {
    if (this.value) {
      this.generated = this.value
    } else {
      this.regenerate()
    }
  },
  methods: {
    regenerate () {
      const team = teams[Math.round(Math.random() * teams.length)]
      const object = objects[Math.round(Math.random() * objects.length)]
      const predicate = predicates[Math.round(Math.random() * predicates.length)]
      const collection = collections[Math.round(Math.random() * collections.length)]
      this.generated = `${team}-${object}-${predicate}-${collection}`
      this.$emit('change', this.generated)
    }
  }
}
</script>
