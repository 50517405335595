import store from '@/store'

export default class Auth {
  constructor () {
    this.store = store
  }

  method () {
    throw new Error('unimplemented')
  }

  async login () {
    throw new Error('unimplemented')
  }

  slug () {
    return store.getters.slug
  }

  domain () {
    return store.getters.domain
  }
}
