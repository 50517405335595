export function bounds (zone) {
  if (!zone || typeof zone !== 'string') return
  const parts = zone.split('.')
  const last = Number(parts.slice(-1)[0])
  return [zone, parts.slice(0, -1).concat(last + 1).join('.')]
}

export const config = {
  peers: {
    indicate: false
  }
}
