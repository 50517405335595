<template>
  <div v-if="ready">
    <div v-if="inline" class="d-flex flex-wrap">
      <template v-for="(options, index) in levelOptions">
        <div v-if="index" :key="index" class="text-light picker-arrow my-auto px-2">
          <i class="fa fa-chevron-right"></i>
        </div>
        <d-form-select class="picker-zone btn"
          :key="index"
          v-model="levelSelections[index]"
          :options="options"
          @input="setValue($event, index)"
        />
      </template>
    </div>
    <d-container v-else class="not-inline">
      <d-row v-for="(options, index) in levelOptions" :key="index" class="mb-2">
        <d-col sm="2" class="my-auto">
          <label class="my-auto mr-2">{{ options[0].level }}: </label>
        </d-col>
        <d-col sm="10">
          <d-form-select class="picker-zone text-left"
            v-model="levelSelections[index]"
            :options="options"
            @input="setValue($event, index)"
          />
          <span v-if="index > minIndex" class="text-light pl-2" :key="index">(optional)</span>
        </d-col>
      </d-row>
      <d-row v-if="location && !hidePath">
        <d-col sm="12">
          <div class="picker-path">{{ path(location) }}</div>
        </d-col>
      </d-row>
    </d-container>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import utils from '@/mixins/utils'

export default {
  name: 'zone-picker',
  props: [
    'id',
    'value',
    'from',
    'rootList',
    'minLevel',
    'maxLevel',
    'inline',
    'hidePath'
  ],
  data () {
    return {
      ready: false,
      rootDepth: undefined,
      location,
      minIndex: undefined,
      maxIndex: undefined,
      levelSelections: [],
      levelOptions: [],
      level0List: []
    }
  },
  mixins: [utils],
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('zones', [
      'zone',
      'subzones'
    ]),
    root () {
      return typeof this.from === 'function' ? this.from() : (this.from || this.user.zone.id)
    }
  },
  async mounted () {
    await this.loadPath({ zone: this.root })
    this.rootDepth = this.depth(this.zone(this.root).level)
    this.minIndex = this.depth(this.minLevel || 'root') - this.rootDepth - 1
    this.maxIndex = this.depth(this.maxLevel || 'area') - this.rootDepth - 1
    this.level0List = this.rootList && [...this.rootList]
    await this.setValue(this.value || this.root)
    this.ready = true
  },
  methods: {
    ...mapActions('zones', [
      'loadPath'
    ]),
    async setValue (id, index) {
      let location
      if (id) {
        await this.loadPath({ zone: id })
        const selectedZone = this.zone(id)
        location = [...selectedZone.location, selectedZone]
        index = this.depth(selectedZone.level) - this.rootDepth
        for (let i = 0; i <= index; i++) {
          if (i > this.maxIndex) break
          const level = this.levels[this.rootDepth + i + 1]
          const parentLevel = this.levels[this.rootDepth + i]
          const levelZone = location.find(z => z.level === level)
          const parentZone = i === index ? this.zone(id) : location.find(z => z.level === parentLevel)
          Vue.set(this.levelSelections, i, levelZone ? levelZone.id : null)
          Vue.set(this.levelOptions, i, [
            { value: null, text: `Select ${this.levelNames[level]}...`, level: this.levelNames[level] },
            ...(this.subzones(parentZone.id) || [])
              .sort((a, b) => this.zoneSort(a, b))
              .filter(zone => !this.level0List || i > 0 || this.level0List.includes(zone.id))
              .map(zone => {
                return { value: zone.id, text: zone.name }
              })
          ])
        }
      } else {
        id = this.levelSelections[index - 1]
      }
      if (index >= this.minIndex + 1 && index <= this.maxIndex + 1) {
        const zone = this.zone(id)
        this.location = zone && [...zone.location, zone]
        this.$emit('change', id, this.location)
        this.$emit('input', id)
      } else {
        this.$emit('change')
        this.$emit('input')
      }
      this.levelSelections = this.levelSelections.slice(0, index + 1)
      this.levelOptions = this.levelOptions.slice(0, index + 1)
    },
    depth (levelName) {
      return this.levels.indexOf(levelName)
    }
  }
}
</script>

<style scoped>
.picker-zone {
  width: 200px;
}
.not-inline .picker-zone {
  font-size: 1em;
}
.picker-path {
  color: #3d5170;
  background-color: #00b8d82b;
  font-weight: bold;
  border-style: solid;
  border-width: 2px;
  border-color: #00b8d8;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
}
</style>
