exports.list = [{
  code: 'activeShooter',
  label: 'Active shooter',
  notification: 'Active shooter reported at %s.  Avoid this location in all circumstances.  Disregard prior notifications about this location.  Follow appropriate procedures.',
  short: 'Active shooter reported at %s.  Avoid this location.'
}, {
  code: 'other',
  label: 'Dangerous emergency',
  notification: 'Dangerous emergency reported at %s.  Avoid this location in all circumstances.  Disregard prior notifications about this location.  Follow appropriate procedures.',
  short: 'Dangerous emergency reported at %s.  Avoid this location.'
}]

exports.options = exports.list.map(e => ({ value: e.code, text: e.label }))

exports.names = Object.fromEntries(exports.list.map(e => [e.code, e.text]))

exports.get = (type) => exports.list.find(({ code }) => code === type)
