import api from '@/lib/api'
import { detectDomain } from '@/lib/util'
import router from '@/router'
import { Ability } from '@casl/ability'
import get from 'lodash/get'

const ability = new Ability()
const detectedDomain = detectDomain() || 'strongline'
const tokenKey = `${window.location.hostname}:${detectedDomain}:auth:token`
const keyKey = `${window.location.hostname}:${detectedDomain}:auth:key`
const roleKey = `${window.location.hostname}:${detectedDomain}:auth:role`
const params = new URLSearchParams(window.location.search)

if (params.get('token')) {
  localStorage[tokenKey] = JSON.stringify({
    token: params.get('token'),
    expiresAt: Number(params.get('expiresAt') || new Date('3000'))
  })
}
if (params.get('key')) {
  sessionStorage[keyKey] = params.get('key')
}
if (params.get('role')) {
  sessionStorage[roleKey] = params.get('role')
}

function loadRefreshToken () {
  try {
    return localStorage[tokenKey] && JSON.parse(localStorage[tokenKey])
  } catch (error) {}
}

// initial state
const state = {
  user: null,
  roles: null,
  loading: true,
  valid: true,
  accessToken: null,
  refreshToken: loadRefreshToken(),
  key: sessionStorage[keyKey],
  roleOverride: sessionStorage[roleKey]
}

// getters
const getters = {
  user: state => state.user,
  redirectError: state => params.get('authError') === '1',
  redirectFail: state => params.get('authFail') === '1',
  accessToken: state => state.accessToken,
  refreshToken: state => state.refreshToken,
  key: state => state.key,
  loading: state => state.loading,
  valid: state => state.valid,
  ability: () => ability,
  can: () => ability.can.bind(ability),
  roles: state => state.roles || [],
  roleOverride: state => state.roleOverride,
  closedNotes: state => get(state, 'user.state.closedNotes') || {}
}

// actions
const actions = {
  login: async ({ commit, state, dispatch, getters }, after = null) => {
    if (getters.key || getters.refreshToken) {
      try {
        commit('SET_LOADING', true)
        const params = {
          ...(getters.roleOverride && { role: getters.roleOverride })
        }
        await dispatch('refreshRoles')
        const { data: user } = await api.get('/users/me', { params })
        const role = state.roles.find(role => role.id === user.role)
        getters.ability.update((role && role.abilities) || [])
        commit('SET_USER', user)
      } catch (error) {
        commit('SET_USER', null)
        commit('SET_KEY', null)
        commit('SET_ROLE_OVERRIDE', null)
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    }
    // Note: we _should_ be able to support all router variants.
    //       the currently supported ones are:
    // - [x] name
    // - [x] path
    // - [ ] path + query
    // - [ ] path + hash
    // - [ ] name + param
    if (after && after.startsWith('/')) {
      router.push({ path: after })
    } else if (after && router.currentRoute.name !== after) {
      router.push({ name: after })
    } else if (router.currentRoute.path !== '/' &&
        !['device', 'deviceV1'].includes(router.currentRoute.name)) {
      router.push('/')
    }
  },
  logout: async ({ commit, getters, rootGetters }, redirectParams) => {
    commit('SET_LOADING', true)
    try {
      const loggedIn = getters.user
      const newSlug = ((redirectParams || {}).query || {}).authSlug
      const newDomain = ((redirectParams || {}).query || {}).authDomain
      getters.ability.update([])
      commit('SET_USER', null)
      commit('SET_REFRESH_TOKEN', null)
      commit('SET_ACCESS_TOKEN', null)
      commit('SET_KEY', null)
      commit('SET_ROLE_OVERRIDE', null)
      commit('SET_SLUG', newSlug, { root: true })
      commit('SET_DOMAIN', newDomain, { root: true })
      commit('SET_VALID', true)
      window.sessionStorage.clear()
      if (redirectParams) {
        router.push(redirectParams)
        window.location.reload()
      } else if (loggedIn) {
        const params = new URLSearchParams()
        if (newSlug) {
          params.append('authSlug', newSlug)
        }
        if (newDomain) {
          params.append('authDomain', newDomain)
        }
        window.location.href = `/login?${params}`
      }
    } catch (error) {
      console.log(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  valid: ({ commit }, valid) => {
    commit('SET_VALID', valid)
  },
  refreshRoles: async ({ commit, getters }) => {
    const { data: roles } = await api.get('roles')
    commit('SET_ROLES', roles)
  },
  closeNote: async ({ commit }, noteId) => {
    await api.put('users/state', {
      closedNotes: {
        [noteId]: true
      }
    })
    commit('SET_NOTE_CLOSED', noteId)
  }
}

// mutations
const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_ACCESS_TOKEN (state, token) {
    state.accessToken = token
  },
  SET_REFRESH_TOKEN (state, token) {
    if (token) {
      localStorage[tokenKey] = JSON.stringify(token)
    } else {
      delete localStorage[tokenKey]
    }
    state.refreshToken = token
  },
  SET_KEY (state, key) {
    if (key) {
      sessionStorage[keyKey] = key
    } else {
      delete sessionStorage[keyKey]
    }
    state.key = key
  },
  SET_ROLE_OVERRIDE (state, role) {
    if (role) {
      sessionStorage[roleKey] = role
    } else {
      delete sessionStorage[roleKey]
    }
    state.roleOverride = role
  },
  SET_VALID (state, valid) {
    state.valid = !!valid
  },
  SET_NOTE_CLOSED (state, noteId) {
    if (!state.user) return
    const closedNotes = (state.user.state || {}).closedNotes || {}
    state.user.state = {
      ...state.user.state,
      closedNotes: {
        ...closedNotes,
        [noteId]: true
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
