<template>
  <d-modal v-if="progress" class="progress-modal">
    <d-modal-header v-if="progress.title">
      <d-modal-title>{{ progress.title }}</d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <div class="step">
        <div><strong>{{ progress.step || 'Please wait...' }}</strong></div>
      </div>
      <d-progress v-if="'percent' in progress" :value="progress.percent" striped animated />
      <d-progress v-else :value="100" striped animated />
      <d-button v-if="progress.cancel !== false" theme="light" class="float-right mt-4" @click.native.stop="onCancel">Cancel</d-button>
    </d-modal-body>
  </d-modal>
</template>

<script>
export default {
  name: 'progress-modal',
  props: ['progress'],
  data () {
    return {
    }
  },
  mounted () {
    this.update()
  },
  watch: {
    progress () {
      this.update()
    }
  },
  methods: {
    onCancel () {
      this.$emit('cancel')
    },
    update () {
    }
  }
}
</script>

<style>
.progress-modal .modal-content {
  margin-top: 200px;
  width: 400px;
  background-color: #ffffffff;
}
.progress-modal .modal-body {
  padding: 30px;
  font-size: 0.85rem;
}
.progress-modal.modal {
  z-index: 5000;
}
</style>

<style scoped>
  .progress {
    margin-top: 10px;
  }
</style>
