export default {
  install (Vue, router, baseUrl = 'distribution') {
    Vue.$goHistory = ['home']

    Vue.prototype.$goSetDefaultParams = (params) => {
      Vue.$goDefaultParams = params
    }

    Vue.prototype.$go = async (name, params) => {
      await router.push({
        name: `${baseUrl}${name ? '-' + name : ''}`,
        params: { ...Vue.$goDefaultParams, ...params }
      })
      Vue.$goHistory.push(name)
    }

    Vue.prototype.$goBack = (name) => {
      if (name) {
        const index = Vue.$goHistory.lastIndexOf(name)
        if (index >= 0) {
          router.go(index - Vue.$goHistory.length + 1)
          Vue.$goHistory = Vue.$goHistory.slice(0, index + 1)
        }
      } else if (Vue.$goHistory.length > 1) {
        router.go(-1)
        Vue.$goHistory.pop()
      }
    }
  }
}
