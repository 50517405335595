import axios from 'axios'
import config from '@/config'

const api = axios.create({
  baseURL: config.api.server + config.api.path,
  timeout: 30000
})

api.interceptors.response.use(
  config => config,
  (error) => {
    const { response } = error
    error.reason = response && response.data && response.data.reason
    return Promise.reject(error)
  }
)

window.axios = api

export default api
