<template>
  <d-container fluid class="h-100">
    <d-row class="h-100">
      <!-- Main Sidebar -->
      <main-sidebar :items="navItems" />

      <d-col class="main-content offset-lg-2 offset-md-3 p-0 h-100" tag="main" lg="10" md="9" sm="12">

        <!-- Main Navbar -->
        <main-navbar />
        <d-alert v-if="disconnected" show theme="warning" data-ref="warning.network">
          WARNING: Your network is disconnected. Alerts will not be shown!
        </d-alert>
        <d-alert v-else-if="user && !valid" show theme="danger" class="font-weight-bold" data-ref="warning.auth">
          WARNING: Alerts will not be shown! There was a problem accessing the server. Trying to reconnect...
        </d-alert>
        <d-alert v-for="dl in (features.danger ? dangerousLocations : [])" :key="dl.id" show theme="danger" class="font-weight-bold" data-ref="warning.auth">
          DANGER: {{ (dl.reason || {}).text || 'Emergency' }} in {{ path(dl.location) }}
        </d-alert>
        <d-alert v-if="$router.currentRoute.name !== 'alerts' && alerts.length && canHearAlerts" show theme="danger" @click.native="showAlerts" data-ref="danger.help">
          There are active requests for help. Click here to view.
        </d-alert>
        <d-alert v-if="alertsMuted" show theme="info" class="font-weight-bold" data-ref="warning.auth">
          NOTE: Alerts are currently being muted. Click on "Dangerous Location > Unmute Alerts" in the "Alerts" tab to unmute.
        </d-alert>
        <!-- Content -->
        <slot />

        <!-- Main Footer -->
        <main-footer />
      </d-col>

    </d-row>
    <edit-modal ref="edit"/>
    <info-modal ref="info"/>
    <url-pattern-modal ref="urlPattern"/>
    <d-modal v-if="muted && canHearAlerts" id="audio-modal" @close="setMuted(false)" @click.native="setMuted(false)">
      <div class="audio-alert container d-flex w-100 h-100">
        <div class="row justify-content-center align-self-center w-100">
          <div class="text-center">
            <div class="p-5">WARNING: Alert audio is disabled! Please click anywhere to enable.</div>
          </div>
        </div>
      </div>
    </d-modal>
  </d-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import sidebarNavItems from '@/data/sidebar-nav-items'
import EditModal from '@/components/EditModal'
import InfoModal from '@/components/InfoModal'
import UrlPatternModal from '@/components/UrlPatternModal'
import { Howl } from 'howler'
import canAutoPlay from 'can-autoplay'
import config from '@/config'
import api from '@/lib/api'
import utils from '@/mixins/utils'

// Main layout components
import MainNavbar from '@/components/layout/MainNavbar/MainNavbar.vue'
import MainSidebar from '@/components/layout/MainSidebar/MainSidebar.vue'
import MainFooter from '@/components/layout/MainFooter/MainFooter.vue'

const alertSound = new Howl({ src: ['/RedAlert.mp3'], loop: true })
if (config.testing) window.$alertSound = alertSound

export default {
  mixins: [utils],
  components: {
    MainNavbar,
    MainSidebar,
    MainFooter,
    EditModal,
    InfoModal,
    UrlPatternModal
  },
  data () {
    const canHearAlerts = this.$can('read', 'Alert', 'sound')
    return {
      canHearAlerts,
      alertToasts: {},
      navItems: []
    }
  },
  mounted () {
    Vue.prototype.$editModal = this.$refs.edit
    Vue.prototype.$infoModal = this.$refs.info
    Vue.prototype.$urlPatternModal = this.$refs.urlPattern
    this.setMuted(false)
    this.updateNavItems()
    try {
      canAutoPlay.audio().then(({ result }) => {
        if (!result && !config.testing) {
          this.setMuted(true)
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    ...mapGetters([
      'connected',
      'muted',
      'alertsMuted'
    ]),
    ...mapGetters('auth', [
      'user',
      'valid'
    ]),
    ...mapGetters('alerts', [
      'alerts',
      'acknowledged',
      'dangerousLocations'
    ]),
    disconnected () {
      return !this.connected
    },
    shouldPlayAlarm () {
      if (this.alertsMuted) return false
      const validStates = ['created', 'notified', 'escalated']
      return this.canHearAlerts && this.alerts.find(alert => validStates.includes(alert.state) && !this.acknowledged[alert.id])
    }
  },
  watch: {
    acknowledged () {
      this.updateState()
    },
    user () {
      if (!this.user) return
      this.updateState()
      this.updateNavItems()
      if (this.canHearAlerts) {
        this.showAlerts()
      } else if (!this.$router.currentRoute.name) {
        const items = (this.navItems[0] || {}).items || []
        const first = items[0] || {}
        if (first.to) {
          this.$router.push({ path: first.to })
        }
      }
    },
    alerts (newAlerts, oldAlerts) {
      this.updateState()
      if (newAlerts.length > oldAlerts.length) {
        this.showAlerts()
      }
    },
    alertsMuted () {
      this.updateState()
    }
  },
  methods: {
    ...mapActions([
      'setMuted'
    ]),
    updateState () {
      if (this.canHearAlerts && this.shouldPlayAlarm) {
        if (!alertSound.playing()) alertSound.play()
      } else {
        alertSound.stop()
      }
    },
    async updateNavItems () {
      const featureFlags = ((this.user.zone.config || {}).featureFlags || {})
      this.navItems = sidebarNavItems
        .map(section => {
          const items = section.items.filter(({ rule }) => !rule || rule(this.$can, featureFlags))
          items.forEach(item => {
            if (item.items) {
              item.items = item.items.filter(({ rule, featureRequired }) => !rule || rule(this.$can, featureFlags))
              if (!item.items.length) {
                delete item.items
              }
            }
          })
          if (section.action) {
            if (section.action.rule && !section.action.rule(this.$can)) {
              delete section.action
            }
          }
          return { ...section, items: items.filter(item => item.items || item.to) }
        })
        .filter(section => section.items.length)
      if (!this.$can('read', 'Report')) {
        return
      }
      api.get('bigquery/routines')
        .then(({ data: routines }) => {
          if (!routines.length) return
          let reportsIndex = this.navItems.findIndex(i => i.title === 'Reports')
          if (reportsIndex === -1) {
            this.navItems.push({ title: 'Reports', items: [] })
            reportsIndex = this.navItems.length - 1
          }
          const reports = this.navItems[reportsIndex]
          routines.forEach(routine => {
            const { group: groupTitle, title } = this.parseRoutineId(routine.id)
            let group = reports.items.find(i => i.title === groupTitle)
            if (!group) {
              group = {
                title: groupTitle,
                htmlBefore: '<i class="fa fa-clipboard"></i>',
                items: []
              }
              reports.items.push(group)
            }
            const to = `/reports/${routine.id}`
            if (!group.items.find(i => i.to === to)) {
              group.items.push({
                title,
                to,
                htmlBefore: '<i class="fa fa-clipboard"></i>',
                htmlAfter: ''
              })
            }
          })
          this.navItems.splice(reportsIndex, 1, reports)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showAlerts () {
      if (this.canHearAlerts && this.$router.currentRoute.name !== 'alerts') {
        this.$router.push({ name: 'alerts' })
      }
    }
  }
}
</script>

<style>
  #audio-modal div.modal-content {
    width: 500px;
    height: 300px;
    background-color: #fff;
  }
</style>

<style scoped>
  .audio-alert {
    border: 40px solid #c4183c;
    color: #c4183c;
    animation: alarm 0.5s linear infinite alternate;
  }
  .alert {
    cursor: pointer;
    animation: alarm 0.5s linear infinite alternate;
  }
  @keyframes alarm {
    from { opacity: 1.0; }
    to { opacity: 0.3; }
  }
</style>
