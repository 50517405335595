import Auth from './auth'
import config from '@/config'

export default class SamlAuth extends Auth {
  method () {
    return 'saml'
  }

  login (options) {
    let query = `?authDomain=${this.domain()}`
    if (options.after) {
      query += `&after=${options.after}`
    }
    const newLocation = `${config.api.server}${config.api.path}/auth/saml/${this.slug()}${query}`

    window.location = newLocation
    return Promise.resolve({})
  }
}
