<template>
  <vue-simple-suggest ref="vss"
    :value="value"
    @select="suggestSelect"
    :list="list"
    :min-length="minLength || 2"
    :max-suggestions="10"
    mode="select"
    @input="onInput"
    @request-done="suggestResults"
    :styles="styles"
    @blur="suggestBlur">
    <suggest-input ref="input" :value="value" :placeholder="placeholder" :qr="qr" @clear="suggestClear" @qr="$emit('qr')" :disabled="disabled" />
    <div slot="suggestion-item" slot-scope="scope">
      <span v-html="suggestItem(scope)"></span>
    </div>
    <template slot="misc-item-below" slot-scope="{ suggestions }">
      <div v-if="suggestions.length >= 10" class="misc-item footer-item">
        Only first 10 matches shown
      </div>
    </template>
  </vue-simple-suggest>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest/lib'
import SuggestInput from '@/components/SuggestInput'

export default {
  name: 'suggest',
  props: ['value', 'list', 'placeholder', 'freeform', 'regex', 'regexTruncateLength', 'qr', 'styles', 'disabled', 'minLength'],
  data () {
    return {
      text: null
    }
  },
  components: {
    VueSimpleSuggest,
    SuggestInput
  },
  watch: {
    value () {
      if (typeof this.value === 'string' && this.value !== this.text) {
        const vss = this.$refs.vss
        if (!vss) return
        this.text = this.value
        vss.setText(this.text)
      }
    }
  },
  methods: {
    suggestItem (scope) {
      if (!scope) return scope
      const { suggestion, query } = scope
      const result = suggestion.title
      if (!query || !result) return result
      try {
        return result.replace(new RegExp('(.*?)(' + query + ')(.*?)', 'gi'), '$1<b style="font-weight: bold;">$2</b>$3')
      } catch (error) {
        return result
      }
    },
    suggestResults (results) {
    },
    suggestSelect (value) {
      if (value) {
        this.value = value
        this.$emit('change', value)
      }
    },
    suggestBlur () {
      const vss = this.$refs.vss
      if (!vss) return
      if (this.freeform && this.text) {
        this.value = this.text
        vss.setText(this.text)
        this.$emit('change', this.text)
        return
      }
      if (this.value && this.value.id) {
        vss.setText(this.value.title)
      } else if (this.value) {
        vss.setText(this.value)
      } else {
        vss.setText('')
      }
    },
    suggestClear () {
      const vss = this.$refs.vss
      if (!vss) return
      this.text = ''
      vss.setText('')
      this.$emit('change', '')
    },
    onInput (text) {
      if (this.regex && text) {
        const match = text.match(this.regex)
        if (match && this.regexTruncateLength && this.text !== match[0]) {
          this.text = match[0].slice(0, this.regexTruncateLength)
          return this.suggestBlur()
        } else if (match && !this.regexTruncateLength && this.text !== text) {
          this.text = text
          return this.suggestBlur()
        }
      }
      this.text = text
    },
    focus () {
      const input = this.$refs.input
      if (input) {
        input.focus()
      }
    }
  }
}
</script>

<style>
.vue-simple-suggest.designed .input-wrapper input {
  padding: 8px;
}
.vue-simple-suggest.designed svg {
  fill: currentColor;
}
.vue-simple-suggest.designed .input-group-text:hover {
  color: #e53935;
}
.vue-simple-suggest.designed .footer-item {
  font-weight: bold;
  font-size: 0.7rem;
}

</style>
