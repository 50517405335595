<template>
  <component :is="layout">
    <router-view v-bind:key="$route.fullPath"></router-view>
    <d-modal v-if="logoutSeconds > 0" id="auto-logout-modal">
      <div class="container d-flex w-100 h-100">
        <div class="row justify-content-center align-self-center w-100">
          <div class="text-center">
            <div>You will automatically be signed out in</div>
            <div class="auto-logout-seconds">{{ logoutSeconds }} seconds</div>
            <div>due to inactivity</div>
          </div>
        </div>
      </div>
    </d-modal>
  </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import HeartBeats from '@/lib/heartbeats'
import IdleTracker from 'idle-tracker'

const hashCheckInterval = 60000
const alertRefreshInterval = 2000
const badgeApp = window.BadgeAndroidApp
const autoLogoutTimeoutSeconds = 15 * 60
const autoLogoutCountdownSeconds = 60

export default {
  data () {
    return {
      heartBeats: null,
      alertRefreshTimer: null,
      idle: null,
      logoutTimer: null,
      logoutSeconds: 0
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'key'
    ]),
    layout () {
      if (!this.$route.name) {
        return 'loading-layout'
      }
      return `${this.$route.meta.layout || 'default'}-layout`
    }
  },
  watch: {
    user (user) {
      if (user) {
        this.heartBeats.start()
        if (this.alertRefreshTimer) {
          clearInterval(this.alertRefreshTimer)
        }
        if (this.$can('read', 'Alert') && !window.GatewayMobileApp) {
          this.alertRefreshTimer = setInterval(() => this.$store.dispatch('alerts/refresh'), alertRefreshInterval)
        }
      } else {
        this.heartBeats.stop()
        if (this.alertRefreshTimer) {
          clearInterval(this.alertRefreshTimer)
        }
        this.$store.dispatch('tagBatchOps/clear')
      }
    }
  },
  created () {
    document.title = `${this.$brand.name}`
    this.heartBeats = new HeartBeats()
    if (!this.idle) {
      this.idle = new IdleTracker({
        timeout: (autoLogoutTimeoutSeconds - autoLogoutCountdownSeconds) * 1000,
        onIdleCallback: (event) => this.onIdle(event)
      })
      this.idle.start()
    }
  },
  destroyed () {
    this.heartBeats.stop()
    if (this.idle) {
      this.idle.end()
      this.idle = null
    }
  },
  mounted () {
    if (['production', 'staging'].includes(process.env.NODE_ENV)) {
      setInterval(() => this.checkHash(), hashCheckInterval)
    }
    if (this.user) {
      this.heartBeats.start()
      if (this.$can('read', 'Alert') && !window.GatewayMobileApp) {
        if (this.alertRefreshTimer) {
          clearInterval(this.alertRefreshTimer)
        }
        this.alertRefreshTimer = setInterval(() => this.$store.dispatch('alerts/refresh'), alertRefreshInterval)
      }
    }
  },
  methods: {
    ...mapActions('provision', [
      'setTag'
    ]),
    ...mapActions('auth', [
      'logout'
    ]),
    async checkHash () {
      try {
        const { data: hash } = await axios.get(`/hash.json?t=${Date.now()}`, {
          responseType: 'text'
        })

        if (window._smpHash !== hash) {
          for (const savedKey of Object.keys(window.sessionStorage)) {
            if (savedKey.startsWith('vuetables')) {
              delete window.sessionStorage[savedKey]
            }
          }
          if (badgeApp && badgeApp.clearCacheAndForceReload) {
            badgeApp.clearCacheAndForceReload()
          } else {
            window.location.reload()
          }
        }
        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistration(`${process.env.BASE_URL}service-worker.js`)
            .then(registration => registration.update())
            .catch(error => console.log(error, 'service_worker_update_check_failed'))
        }
      } catch (err) {
        // console.log('Could not retrieve app hash:', err)
      }
    },
    shouldAutoLogout (idle) {
      const canBypass = this.$can('delete', 'Timer', 'logout') || this.key
      return this.user && idle && !this.logoutTimer && !canBypass
    },
    onIdle ({ idle }) {
      if (this.shouldAutoLogout(idle)) {
        this.logoutSeconds = autoLogoutCountdownSeconds
        this.logoutTimer = setInterval(() => {
          if (--this.logoutSeconds <= 0) {
            clearInterval(this.logoutTimer)
            this.logoutTimer = null
            this.logout()
          }
        }, 1000)
      } else if (!idle) {
        clearInterval(this.logoutTimer)
        this.logoutTimer = null
        this.logoutSeconds = 0
      }
    }
  }
}
</script>

<style>
#auto-logout-modal div.modal-content {
  width: 500px;
  height: 300px;
  background-color: #fff;
  border: 40px solid #c4183c;
  color: #c4183c;
}
.auto-logout-seconds {
  font-size: 2em;
}
span.nobreak {
  white-space: nowrap;
}
</style>
