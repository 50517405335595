<template>
  <loading
    :active="isLoading"
    :is-full-page="false"
    color="#007bff"
    loader="bars"
    :opacity="0.7"
    :z-index="999999"
  >
    <!-- <div slot="default">
      <i class="loading-spinner fa fa-redo-alt mr-1" />
    </div> -->
  </loading>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'loading-overlay',
  props: ['isLoading'],
  components: {
    Loading
  }
}
</script>

<style scoped>
  .loading-spinner {
    color: #007bff;
    font-size: 36px;
    animation: rotating 1s linear infinite;
  }
  @keyframes rotating {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
</style>
