<template>
  <label class="custom-control" :class="labelClass" v-d-tooltip.hover :title="tooltip">
    <input type="checkbox" :class="`${type}-switch-control-input`" v-model="value" :disabled="disabled" @change="$emit('input', $event.target.checked)">
    <span :class="`${type}-switch-control-indicator`"></span>
    <span :class="`${type}-switch-control-description`">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'toggle',
  props: {
    value: { type: Boolean, default: false },
    type: { type: String, default: 'ios' },
    label: { type: String },
    tooltip: { type: String },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    labelClass () {
      let text = `${this.type}-switch`
      if (this.disabled) {
        text += ' switch-disabled'
      }
      return text
    }
  }
}
</script>

<style scoped>
.material-switch {
  padding-left: 0;
}
.material-switch .material-switch-control-input {
  display: none;
}
.material-switch .material-switch-control-input:checked ~ .material-switch-control-indicator::after {
  background-color: #17c671;
  left: 17px;
}
.material-switch .material-switch-control-indicator {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  top: 4px;
  width: 32px;
  height: 16px;
  background: #e9ecef;
  border-radius: 16px;
  transition: .3s;
}
.material-switch .material-switch-control-indicator::after {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: .3s;
  top: -1px;
  left: -1px;
  background: #fdfdfd;
  box-shadow: 0 2px 10px #aaa;
}

.ios-switch {
  padding-left: 0;
}
.ios-switch .ios-switch-control-input {
  display: none;
}
.ios-switch .ios-switch-control-input:active ~ .ios-switch-control-indicator::after {
  width: 20px;
}
.ios-switch .ios-switch-control-input:checked ~ .ios-switch-control-indicator {
  border: 10px solid #17c671;
}
.ios-switch .ios-switch-control-input:checked ~ .ios-switch-control-indicator::after {
  top: -8px;
  left: 4px;
}
.ios-switch .ios-switch-control-input:active ~ .ios-switch-control-indicator::after {
  left: 0px;
}
.ios-switch .ios-switch-control-indicator {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  top: 4px;
  width: 32px;
  height: 20px;
  background: #fff;
  border-radius: 16px;
  transition: .3s;
  border: 2px solid #ddd;
}
.ios-switch .ios-switch-control-indicator::after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  transition: .3s;
  top: 0px;
  left: 0px;
  background: #fff;
  box-shadow: 0 0 2px #aaa, 0 2px 5px #999;
}
.switch-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
