module.exports = {
  google: {
    apiKey: 'AIzaSyClZExpZ_TcAzGAxam8D_mbyBEcOb7WbqE'
  },
  api: {
    server: process.env.VUE_APP_API || 'http://localhost:8000',
    path: '/v1',
    maxAuthRetries: 1
  },
  testing: !!process.env.VUE_APP_TESTING,
  agGridLicenseKey: 'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-060472}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Canopy_Protect}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Canopy_Protect}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Canopy_Protect}_need_to_be_licensed___{Canopy_Protect}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{22_May_2025}____[v3]_[0102]_MTc0Nzg2ODQwMDAwMA==708a8f717303bcea836ebf1127f6dd9c',
  domains: { // copied from processor config. Keep in sync
    strongline: {
      branding: {
        domain: 'strongline',
        name: 'Strongline',
        company: 'Strongline'
      },
      host: {
        production: 'strongline.smplabs.com',
        staging: 'strongline-staging.smplabs.com',
        local: 'strongline-dev.smplabs.com'
      }
    },
    canopyprotect: {
      branding: {
        domain: 'canopyprotect',
        name: 'Canopy Protect',
        company: 'Canopy'
      },
      host: {
        production: 'canopyprotect.com',
        staging: 'canopyprotect-staging.com',
        local: 'canopyprotect-dev.com'
      }
    }
  },
  logistics: { // copied from processor config. Keep in sync
    factorySlug: 'factory',
    warehouseSlug: 'smpwarehouse',
    inventorySlug: {
      canopyprotect: 'inventory',
      strongline: 'strongline-inventory'
    }
  }
}
