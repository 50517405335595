import EventEmitter from 'events'
import router from '@/router'
import { getDocument } from '@/lib/dom'

const defaultOptions = {
  minInterval: 1000
}

function debounce (fn, delay) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn(...args), delay)
  }
}

function isValidRoute () {
  return router.currentRoute && ['alerts', 'alertLogs'].includes(router.currentRoute.name)
}

class Snapshotter extends EventEmitter {
  constructor (options) {
    super()
    this.options = { ...defaultOptions, ...options }
    this.node = null
    this.started = false
    const snap = this.snapper()
    this.observer = new MutationObserver(mutations => {
      if (!isValidRoute()) return
      if (mutations.length) {
        snap(mutations)
      }
    })
  }

  snapper () {
    return debounce(async (mutations) => {
      const snapshot = await getDocument()
      this.emit('snapshot', snapshot)
    }, this.options.minInterval)
  }

  async start (node) {
    if (this.started) return
    this.started = true
    this.node = node || document.body
    if (isValidRoute()) {
      this.emit('snapshot', await getDocument())
    }
    this.observer.observe(this.node, {
      attributes: true,
      childList: true,
      subtree: true
    })
  }

  stop () {
    if (!this.started) return
    this.started = false
    this.observer.disconnect()
  }
}

export default Snapshotter
