import Auth from './auth'
import config from '@/config'

export default class GoogleAuth extends Auth {
  method () {
    return 'google'
  }

  login (options) {
    let query = `?authDomain=${this.domain()}`
    if (options.after) {
      query += `&after=${options.after}`
    }
    const url = `${config.api.server}${config.api.path}/auth/google/${this.slug()}${query}`

    window.location = url
    return Promise.resolve({})
  }
}
