<template>
  <div class="form-group">
    <d-select :value="reasonCode" :options="options" @change="reasonChanged($event)" />
    <d-input class="mt-2" v-if="reasonCode.includes('other')" type="text" v-model="reasonText" placeholder="Enter reason" @change="emitReason" :required=true />
  </div>
</template>

<script>
export default {
  name: 'generator',
  props: {
    value: { type: Object },
    options: { type: Array }
  },
  data () {
    return {
      reasonCode: '',
      reasonText: ''
    }
  },
  mounted () {
    this.reasonCode = this.value.code
    this.reasonText = this.reasonCode.includes('other') ? this.value.text : ''
  },
  methods: {
    emitReason () {
      const newReason = { code: this.reasonCode, text: this.reasonText }
      this.$emit('change', newReason)
    },
    reasonChanged (code) {
      this.reasonCode = code
      this.emitReason()
    }
  }
}
</script>
