import Auth from './auth'

export default class KeyAuth extends Auth {
  method () {
    return 'key'
  }

  async login ({ key }) {
    this.store.commit('auth/SET_KEY', key)
    const badgeApp = window.BadgeAndroidApp
    if (badgeApp && badgeApp.saveAPIKey) {
      badgeApp.saveAPIKey(key)
    }
  }
}
