<template>
  <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
    <d-container fluid>
      <d-row class="py-2">
        <d-col col lg="5" class="text-left pl-0">
          <span class="copyright ml-2 my-auto"><small>{{ time }} {{ org ? `(${org})` : '' }}</small></span>
        </d-col>
        <d-col col lg="2" class="text-center">
          <div id="console-id" class="py-1" @click="copyToClipboard(consoleId)">
            <span data-ref="console.id">{{ consoleId }}</span>
            <d-tooltip target="#console-id">Click to copy</d-tooltip>
          </div>
        </d-col>
        <d-col col lg="5" class="text-right pr-0">
          <span class="copyright ml-auto my-auto mr-2"><small>{{ copyright }}</small></span>
        </d-col>
      </d-row>
    </d-container>
  </footer>
</template>

<script>
import { consoleId } from '@/lib/console'
import { mapGetters } from 'vuex'
import moment from 'moment'
import utils from '@/mixins/utils'

export default {
  name: 'main-footer',
  mixins: [utils],
  props: {
    /**
       * The copyright information.
       */
    copyright: {
      type: String,
      default: `Copyright © ${moment().year()} SMP Labs, Inc.`
    },
    /**
       * Whether the footer should be wrapped in a container, or not.
       */
    contained: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      consoleId: null,
      org: null,
      time: ''
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    organization () {
      const zone = this.user && this.user.zone
      if (zone && zone.level === 'org') {
        return zone.name
      }
      return ''
    }
  },
  mounted () {
    this.consoleId = consoleId()
    this.interval = setInterval(() => this.updateTime(), 500)
    if (Array.isArray(this.user && this.user.location)) {
      const org = this.user.location.find(zone => zone.level === 'org')
      if (org) {
        this.org = org.name
      }
    }
  },
  destroyed () {
    clearInterval(this.interval)
  },
  methods: {
    updateTime () {
      this.time = moment.tz(new Date(), moment.tz.guess()).format('ddd, MMM Do, h:mm:ss a z')
    }
  }
}
</script>

<style scoped>
#console-id {
  color: #3d5170;
  background-color: #f5f6f8;
  font-size: 1em;
  border-style: dashed;
  border-width: 1px;
  border-color: #818ea3;
  cursor: pointer;
}
</style>
