import zlib from 'zlib'
import { promisify } from 'util'
const unzip = promisify(zlib.unzip)
const deflate = promisify(zlib.deflate)

function html () {
  return document.body.outerHTML
}

function css () {
  const styleSheets = Array.from(document.styleSheets)
  const links = styleSheets
    .filter(ss => ss.href)
    .map(ss => ss.href)
  const text = styleSheets
    .filter(ss => !ss.href)
    .map(ss => Array.from(ss.rules).map(r => r.cssText).join('\n'))
    .join('\n')
  return { links, text }
}

function renderHtmlCss (node, html, css) {
  const { links, text } = css
  node.innerHTML = ''
  const iframe = document.createElement('iframe')
  node.appendChild(iframe)
  const doc = iframe.contentWindow.frames.document
  links.forEach(href => {
    const link = doc.createElement('link')
    link.rel = 'stylesheet'
    link.href = href
    doc.head.appendChild(link)
  })
  const style = doc.createElement('style')
  style.type = 'text/css'
  style.appendChild(doc.createTextNode(text))
  doc.head.appendChild(style)
  doc.body.outerHTML = html
}

export async function getHtml () {
  const buffer = await deflate(html())
  return buffer.toString('base64')
}

export async function getCss () {
  const buffer = await deflate(JSON.stringify(css()))
  return buffer.toString('base64')
}

export async function getDocument () {
  const doc = { html: html(), css: css() }
  const buffer = await deflate(JSON.stringify(doc))
  const data = buffer.toString('base64')
  return data
}

export async function render (node, data) {
  const { html, css } = JSON.parse(await unzip(Buffer.from(data, 'base64')))
  renderHtmlCss(node, html, css)
}
